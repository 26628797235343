import { Input, InputProps, useTheme } from '@mui/material';
import { forwardRef, KeyboardEvent } from 'react';
import { Key } from 'ts-key-enum';

interface CustomCellEditableProps {
  value: string;
  textAlign?: string;
  onChangeValue: (value: string) => void;
  onValidateValue: (value: string) => void;
}

type CellEditableProps = InputProps & CustomCellEditableProps;

const CellEditable = forwardRef<HTMLInputElement, CellEditableProps>(
  ({ onChangeValue, onValidateValue, textAlign = 'center', sx, value, ...props }, ref) => {
    const { palette, shape } = useTheme();

    const onEnter = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      event.key === Key.Enter && onValidateValue(value);

    return (
      <Input
        {...props}
        value={value}
        ref={ref}
        onClick={event => event.preventDefault()}
        onKeyUp={onEnter}
        onBlur={() => onValidateValue(value)}
        disableUnderline
        sx={{
          maxWidth: '70px',
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none' },
          '& input': { py: 0, px: 0, textAlign },
          '&:hover, &:focus, &.Mui-focused ': {
            '&.MuiInputBase-root': { borderRadius: 1, border: `1px solid ${palette.grey[200]}` },
          },
          ...sx,
          '&.MuiInputBase-root': {
            borderRadius: shape.borderRadius,
            border: '1px solid transparent',
          },
        }}
        onChange={event => onChangeValue(event.currentTarget.value)}
      />
    );
  },
);

export default CellEditable;
