import { FC } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { DIRECTIONS } from '@/components/Carousel/consts';

interface CustomArrowProps {
  sx?: SxProps;
  className?: string;
  direction: DIRECTIONS;
  arrowPosition?: number;
  onClick?: () => void;
}

const CustomArrow: FC<CustomArrowProps> = ({ sx, className, direction, arrowPosition = 0, onClick }) => {
  const { palette } = useTheme();
  const isDisabled = className?.includes('slick-disabled');
  if (isDisabled) return null;

  return (
    <Box
      className="slider-arrows"
      component="button"
      onClick={onClick}
      sx={{
        position: 'absolute',
        p: 0,
        top: '50%',
        [direction === DIRECTIONS.NEXT ? 'right' : 'left']: arrowPosition,
        [direction === DIRECTIONS.NEXT ? 'left' : 'right']: 'unset',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        color: palette.grey[700],
        transform: `rotate(${direction === DIRECTIONS.NEXT ? 0 : 180}deg) translateY(${
          direction === DIRECTIONS.NEXT ? '-50%' : '50%'
        })`,
        zIndex: 1,
        '&.Mui-disabled': {
          color: palette.grey[200],
        },
        ...sx,
      }}
    >
      <Icon name="arrowBold" fontSize="large" />
    </Box>
  );
};

export default CustomArrow;
