import { TreeItem } from '@/views/Project/views/AutomatePages/types';

export const TEMPLATE_FOLDER: TreeItem = {
  id: 'template-folder',
  name: 'Templates',
  isFolder: true,
  isTemplateFolder: true,
  isStatic: true,
  order: Infinity,
};
