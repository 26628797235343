import { Navigate, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from '@/components/Loadable/Loadable';
import Root from '@/containers/Root/Root';
import { ROUTER_IDS, ROUTER_PATHS, toProjects } from '@/services/linker';
import Projects from '@/views/Projects/Projects';
import ProjectContainer from '@/containers/Project/ProjectContainer';
import OrgKnowledgeBase from '@/views/Knowledge/OrgKnowledgeBase';
import OrgAutomatePages from '@/views/OrgAutomatePages/OrgAutomatePages';
import LayoutStateProvider from '@/views/Project/providers/LayoutStateProvider';

const ProjectSideBySide = Loadable(
  lazy(() => import('@/views/Project/views/projectRoutes').then(module => ({ default: module.SideBySide }))),
);

export default [
  {
    element: <Root />,
    path: ROUTER_PATHS.HOME,
    children: [
      {
        id: ROUTER_IDS.HOME,
        path: ROUTER_PATHS.HOME,
        element: <Navigate to={toProjects()} />,
      },
      {
        id: ROUTER_IDS.ORG_KB,
        path: ROUTER_PATHS.ORG_KB,
        element: <OrgKnowledgeBase />,
      },
      {
        id: ROUTER_IDS.ORG_PAGES,
        path: ROUTER_PATHS.ORG_PAGES,
        element: (
          <LayoutStateProvider>
            <OrgAutomatePages />
          </LayoutStateProvider>
        ),
      },
      {
        id: ROUTER_IDS.PROJECTS,
        path: ROUTER_PATHS.PROJECTS,
        element: <Projects />,
      },
      {
        path: ROUTER_PATHS.PROJECT,
        element: <ProjectContainer />,
        children: [
          {
            id: ROUTER_IDS.PROJECT,
            path: ROUTER_PATHS.PROJECT,
            element: <ProjectSideBySide />,
            children: [
              {
                id: ROUTER_IDS.PROJECT_HOME_PAGE,
                path: ROUTER_PATHS.PROJECT_HOME_PAGE,
                lazy: async () => {
                  const { Homepage } = await import('@/views/Project/views/projectRoutes');
                  return { element: <Homepage /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_TAKEOFF,
                path: ROUTER_PATHS.PROJECT_TAKEOFF,
                lazy: async () => {
                  const { Takeoff } = await import('@/views/Project/views/projectRoutes');
                  return { element: <Takeoff /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_QUERIES,
                path: ROUTER_PATHS.PROJECT_QUERIES,
                lazy: async () => {
                  const { Queries } = await import('@/views/Project/views/projectRoutes');
                  return { element: <Queries /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_PAGES,
                path: ROUTER_PATHS.PROJECT_PAGES,
                lazy: async () => {
                  const { AutomatePages } = await import('@/views/Project/views/projectRoutes');
                  return { element: <AutomatePages /> };
                },
              },
              {
                id: ROUTER_IDS.PROJECT_COMPARE,
                path: ROUTER_PATHS.PROJECT_COMPARE,
                lazy: async () => {
                  const { ComparePanel } = await import('@/views/Project/views/projectRoutes');
                  return { element: <ComparePanel /> };
                },
              },
            ],
          },
        ],
      },
    ],
  },
] satisfies RouteObject[];
