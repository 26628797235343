import { FC } from 'react';
import { Paper } from '@mui/material';
import MarkdownText from '@/components/MarkdownText';

interface AiResponseReviewProps {
  aiText: string;
}

const AiResponseReview: FC<AiResponseReviewProps> = ({ aiText }) => (
  <Paper
    sx={{
      maxHeight: 200,
      p: 2,
      overflow: 'auto',
      fontSize: 'body2.fontSize',
      scrollbarWidth: 'thin',
      '& p': { m: 0 },
      '& table, tr, th, td': { borderCollapse: 'collapse', border: '1px solid #bbb' },
      '& th': { backgroundColor: '#f2f3f5' },
      '& th, td': { p: 1 },
    }}
  >
    <MarkdownText markdown={aiText} />
  </Paper>
);

export default AiResponseReview;
