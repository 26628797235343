import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { createPortal } from 'react-dom';
import { getSelectedNode } from '@lexical/playground/utils/getSelectedNode';
import { mergeRegister, $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
import { $isCodeHighlightNode } from '@lexical/code';
import { $isListNode, ListNode } from '@lexical/list';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $isLinkNode } from '@lexical/link';
import TextFormatFloatingToolbar from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/components/TextFormatFloatingToolbar';
import {
  blockTypes,
  ChangeableBlockType,
  defaultBgColor,
  defaultTextColor,
} from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/constants';
import { ASK_AI_COMMAND } from '@/containers/PagesEditor/plugins/AskAiPlugin/AskAiPlugin';

export default function FloatingTextFormatToolbarPlugin({
  anchorElem = document.body,
  setIsLinkEditMode,
}: {
  anchorElem?: HTMLElement;
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [isText, setIsText] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isSelection, setIsSelection] = useState(false);
  const [blockType, setBlockType] = useState<ChangeableBlockType>('paragraph');
  const [textColor, setTextColor] = useState<string>();
  const [bgColor, setBgColor] = useState<string>();

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return;
      }

      const selection = $getSelection();
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();

      if (
        !$isRangeSelection(selection) ||
        (nativeSelection !== null &&
          (!$isRangeSelection(selection) || rootElement === null || !rootElement.contains(nativeSelection.anchorNode)))
      ) {
        setIsSelection(false);
        return;
      }

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      setIsSelection(true);
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const matchingParent = e.getParent();
              return matchingParent !== null && $isRootOrShadowRoot(matchingParent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if ((blockTypes as ReadonlyArray<string>).includes(type)) {
            setBlockType(type as ChangeableBlockType);
          }
        }
      }

      if ($isCodeHighlightNode(selection.anchor.getNode())) {
        setIsText(false);
      } else if (selection.getTextContent() !== '') {
        setIsText($isTextNode(node) || $isParagraphNode(node));
      } else {
        setIsSelection(false);
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, '');
      if (!selection.isCollapsed() && rawTextContent === '') {
        setIsSelection(false);
        setIsText(false);
        return;
      }

      setTextColor($getSelectionStyleValueForProperty(selection, 'color', defaultTextColor) || undefined);
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', defaultBgColor) || undefined);
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(() => {
          updatePopup();
        }),
        editor.registerRootListener(() => {
          if (editor.getRootElement() === null) setIsText(false);
        }),
        editor.registerCommand(
          ASK_AI_COMMAND,
          () => {
            setIsSelection(false);
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, updatePopup],
  );

  if (!isSelection || !editor.isEditable()) return null;

  return createPortal(
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      selectedBlockType={blockType}
      textColor={textColor}
      bgColor={bgColor}
      isText={isText}
      isLink={isLink}
      isBold={isBold}
      isItalic={isItalic}
      isUnderline={isUnderline}
      setIsLinkEditMode={setIsLinkEditMode}
    />,
    anchorElem,
  );
}
