import camelCase from 'lodash/camelCase';
import { Style } from '@react-pdf/types';

export const convertStringStyleToObject = (styles: string) => {
  if (!styles) return {};

  return styles.split(';').reduce((acc, style) => {
    if (!style.trim()) return acc;

    const [name, prop] = style.split(':');
    acc.push({ [camelCase(name.trim())]: prop.trim() });

    return acc;
  }, [] as Style[]);
};
