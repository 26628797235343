import { ComponentPickerOption } from '@/containers/PagesEditor/plugins/ComponentPickerPlugin/components/ComponentPickerOption';
import { ListItem, Stack, Typography, useTheme } from '@mui/material';

const ComponentPickerMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: ComponentPickerOption;
}) => {
  const { palette, spacing, typography } = useTheme();

  const commonListItemStyles = {
    margin: 0,
    minWidth: 180,
    outline: 'none',
    cursor: 'pointer',
    padding: spacing(1.5, 1.5, 1.5, 3),
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
    backgroundColor: isSelected ? palette.primary.main : palette.background.default,
    borderBottom: `1px solid ${palette.grey[200]}`,
    '&.group-item': { backgroundColor: palette.background.default, '& .text': { color: palette.secondary.dark } },
    '& .text': {
      color: isSelected ? palette.primary.dark : palette.secondary.dark,
    },
    '& .subText': {
      color: isSelected ? palette.secondary.dark : palette.grey[700],
    },
    '&:hover': {
      backgroundColor: palette.grey[100],
      '& .text': {
        color: palette.primary.dark,
      },
      '& .subText': {
        color: palette.grey[700],
      },
    },
    '&:first-of-type': {
      borderRadius: spacing(1, 1, 0, 0),
    },
    '&:last-of-type': {
      borderRadius: spacing(1, 1, 0, 0),
    },
    svg: {
      color: palette.primary.dark,
    },
  };

  const commonTextStyles = {
    display: 'flex',
    lineHeight: 'normal',
    flexGrow: 1,
    minWidth: 150,
    fontWeight: typography.fontWeightMedium,
    fontSize: typography.body3.fontSize,
  };

  if (option.isGroupTitle) {
    return (
      <ListItem
        key={option.key}
        className="group-item"
        sx={{
          ...commonListItemStyles,
          cursor: 'auto',
          '&:hover': { color: palette.secondary.dark, backgroundColor: 'transparent' },
        }}
        role="presentation"
      >
        <Typography sx={commonTextStyles} className="text">
          {option.title}
        </Typography>
      </ListItem>
    );
  }

  return (
    <ListItem
      key={option.key}
      tabIndex={-1}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      sx={commonListItemStyles}
    >
      {option.icon}
      <Stack sx={{ display: 'flex', gap: 0.25 }}>
        <Typography sx={commonTextStyles} className="text">
          {option.title}
        </Typography>
        <Typography
          sx={{ ...commonTextStyles, fontSize: typography.body4.fontSize, fontWeight: 'normal' }}
          className="text subText"
        >
          {option.subTitle}
        </Typography>
      </Stack>
    </ListItem>
  );
};

export default ComponentPickerMenuItem;
