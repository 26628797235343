import { FC, useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, CircularProgress, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudFileView from '@/views/Projects/components/ProjectFormDialog/components/CloudFileView';
import nextTick from '@/services/nextTick';
import Dialog from '@/components/Dialog/Dialog';
import {
  AutodeskFile,
  useGetAutodeskFiles,
  useGetAutodeskHubs,
  useGetAutodeskProjects,
  useGetMicrosoftFiles,
} from '@/api/generated';
import { DriveNode, DriveFileType, OneDriveFile } from '@/types';
import OneDriveIcon from '@/assets/onedrive.svg?react';
import GoogleDriveIcon from '@/assets/google-drive.svg?react';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import Icon from '@/components/Icon/Icon';
import { CloudFilesDialogErrorCallback } from '@/contexts/CloudFilesDialogContext';

export type DefaultParams = {
  folderId?: string;
  hubId?: string;
  projectId?: string;
};

interface CloudFilesDialogProps {
  defaultParams?: DefaultParams;
  driveFilesType: 'none' | DriveFileType;
  onNodeSelect: (node: DriveNode) => void;
  onClose: () => void;
  onError: CloudFilesDialogErrorCallback;
}

const STALE_TIME = 60_1000;
const ROOT_FOLDER = 'None';

const CloudFilesDialog: FC<CloudFilesDialogProps> = ({ defaultParams, driveFilesType, onNodeSelect, onClose, onError }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const isOpened = driveFilesType !== 'none';
  const { folderId: defaultFolderId = ROOT_FOLDER, hubId: defaultHubId, projectId: defaultProjectId } = defaultParams ?? {};
  const [hubId, setHubId] = useState<string | undefined>(defaultHubId);
  const [projectId, setProjectId] = useState<string | undefined>(defaultProjectId);
  const [folderId, setFolderId] = useState(defaultFolderId);
  const [breadcrumbs, setBreadcrumbs] = useState<DriveNode[]>([]);

  const {
    data: oneDriveFiles,
    isLoading: isOneDriveFilesLoading,
    error: microsoftError,
  } = useGetMicrosoftFiles<OneDriveFile[] | undefined>(
    { folder_id: folderId === ROOT_FOLDER ? undefined : folderId },
    { query: { enabled: driveFilesType === 'onedrive', staleTime: STALE_TIME } },
  );

  const {
    data: autodeskHubs,
    isLoading: isHubsLoading,
    error: hubsError,
  } = useGetAutodeskHubs<unknown[]>({ query: { enabled: driveFilesType === 'autodesk', staleTime: STALE_TIME } });

  useEffect(() => {
    if (autodeskHubs?.length === 1) {
      // @ts-expect-error
      setHubId(autodeskHubs[0].id);
    }
  }, [autodeskHubs]);

  const {
    data: autodeskProjects,
    isLoading: isProjectsLoading,
    error: projectsError,
  } = useGetAutodeskProjects<unknown[]>(
    { hub_id: hubId! },
    { query: { enabled: driveFilesType === 'autodesk' && !!hubId, staleTime: STALE_TIME } },
  );

  useEffect(() => {
    if (autodeskProjects?.length === 1) {
      // @ts-expect-error
      setProjectId(autodeskProjects[0].id);
    }
  }, [autodeskProjects]);

  const {
    data: autodeskFiles,
    isLoading: isAutodeskFilesLoading,
    error: autodeskError,
  } = useGetAutodeskFiles<AutodeskFile[] | undefined>(
    { hub_id: hubId!, project_id: projectId!, folder_id: folderId === ROOT_FOLDER ? undefined : folderId },
    { query: { enabled: driveFilesType === 'autodesk' && !!hubId && !!projectId, staleTime: STALE_TIME } },
  );

  useEffect(() => {
    if (!isOpened) {
      nextTick(() => {
        setFolderId(ROOT_FOLDER);
        setBreadcrumbs([]);
      });
    }
  }, [isOpened]);

  useEffect(() => {
    const error = microsoftError || hubsError || projectsError || autodeskError;
    if (error && driveFilesType !== 'none') onError({ error, type: driveFilesType, folderId, hubId, projectId });
  }, [driveFilesType, microsoftError, hubsError, projectsError, autodeskError]);

  const autodeskDriveFiles = (
    !hubId && autodeskHubs ? autodeskHubs : !projectId && autodeskProjects ? autodeskProjects : autodeskFiles
  ) as AutodeskFile[];
  const nodes = useMemo(() => {
    if (driveFilesType === 'none' || driveFilesType === 'google') return [];

    const filesMap = {
      onedrive: oneDriveFiles,
      autodesk: autodeskDriveFiles,
    };
    const transformerMap = {
      onedrive: (native: OneDriveFile) => ({ id: native.id, name: native.name, isFolder: !!native.folder, native }),
      autodesk: (native: AutodeskFile) => ({
        id: native.id,
        name: native.name,
        isFolder: native.type === 'folders',
        native: { ...native, project_id: projectId! },
      }),
    };
    // @ts-expect-error
    return (filesMap[driveFilesType] ?? []).map(file => transformerMap[driveFilesType](file));
  }, [driveFilesType, oneDriveFiles, autodeskDriveFiles]) satisfies DriveNode[];

  const isLoading = useMemo(() => {
    if (driveFilesType === 'onedrive') return isOneDriveFilesLoading;
    return isHubsLoading || isProjectsLoading || isAutodeskFilesLoading;
  }, [driveFilesType, isHubsLoading, isProjectsLoading, isAutodeskFilesLoading, isOneDriveFilesLoading]);

  const onBreadcrumbRootClick = () => {
    setFolderId(ROOT_FOLDER);
    setBreadcrumbs([]);
  };

  const onBreadcrumbClick = (index: number) => {
    const nextFolder = breadcrumbs[index];
    setFolderId(nextFolder.id);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  const onNodeClick = (node: DriveNode) => {
    if (node.isFolder) {
      setFolderId(node.id);
      setBreadcrumbs(prevBreadcrumbs => [...prevBreadcrumbs, node]);
    } else {
      onNodeSelect(node);
    }
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <Stack sx={{ gap: 1, height: 300 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
          {driveFilesType === 'google' && <GoogleDriveIcon style={{ width: 40 }} />}
          {driveFilesType === 'onedrive' && <OneDriveIcon style={{ width: 50 }} />}
          {driveFilesType === 'autodesk' && <AutodeskIcon style={{ width: 50 }} />}
          {driveFilesType === 'google' && t('uploadFiles.cloudsUpload.google')}
          {driveFilesType === 'onedrive' && t('uploadFiles.cloudsUpload.onedrive')}
          {driveFilesType === 'autodesk' && t('uploadFiles.cloudsUpload.autodesk')}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
            fontSize: 'body3.fontSize',
            lineHeight: 'body3.lineHeight',
          }}
        >
          <ButtonBase
            sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: 'inherit', lineHeight: 'inherit' }}
            disabled={breadcrumbs.length === 0}
            onClick={() => onBreadcrumbRootClick()}
          >
            <Icon name="folderOpen" htmlColor={breadcrumbs.length === 0 ? palette.darkPurple.dark : palette.grey[700]} />
            Root
          </ButtonBase>

          {breadcrumbs.map((breadcrumb, index) => (
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
              <Box>/</Box>
              <Icon
                name="folderOpen"
                htmlColor={index === breadcrumbs.length - 1 ? palette.darkPurple.dark : palette.grey[700]}
              />
              <ButtonBase
                sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                disabled={index === breadcrumbs.length - 1}
                onClick={() => onBreadcrumbClick(index)}
              >
                {breadcrumb.name}
              </ButtonBase>
            </Box>
          ))}
        </Box>

        {isLoading ? (
          <CircularProgress sx={{ mt: 3 }} />
        ) : (
          <Stack gap={0.5} sx={{ mt: 1, pb: 3 }}>
            {nodes.map(node => (
              <CloudFileView type={node.isFolder ? 'folder' : 'file'} name={node.name} onClick={() => onNodeClick(node)} />
            ))}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};

export default CloudFilesDialog;
