import { FC, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom';
import { createActionTypeParams } from '@/utils/createActionTypeParams';
import { MainPageAction } from '@/api/generated';
import Carousel from '@/components/Carousel/Carousel';
import RecentlyViewedItem from '@/views/Projects/Documentations/RecentlyViewedItem';
import CreateNewProjectButton from '@/views/Projects/Documentations/CreateNewProjectButton';
import { CARD_HEIGHT } from '@/views/Projects/Documentations/constants';

interface RecentlyViewedProps {
  recentlyViewedItems?: MainPageAction[];
  navigate: NavigateFunction;
  setSearchParams: SetURLSearchParams;
}

const CARD_WIDTH = 160;
const PADDING_TOP = 1.25;
const PADDING_BOTTOM = 2.5;
const PADDING_X = 2;

const responsive = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 4,
    },
  },
];

const RecentlyViewed: FC<RecentlyViewedProps> = memo(
  function RecentlyViewed({ recentlyViewedItems, navigate, setSearchParams }) {
    const { spacing } = useTheme();
    const recentlyViewedRef = useRef<HTMLDivElement | null>(null);
    const [isRecentViewedCarousel, setIsRecentViewedCarousel] = useState(false);
    const [initialCards, setInitialCard] = useState(recentlyViewedItems?.slice(0, 4));

    useEffect(() => {
      if (!recentlyViewedRef.current || !isRecentViewedCarousel) return;
      setInitialCard(recentlyViewedItems);
    }, [recentlyViewedItems, isRecentViewedCarousel]);

    const allCardsWidth = CARD_WIDTH * ((recentlyViewedItems?.length || 0) + 1);

    const switchCarouselOrPlainBlock = useCallback(() => {
      if (!recentlyViewedRef.current) return;
      setIsRecentViewedCarousel(recentlyViewedRef?.current.getBoundingClientRect().width < allCardsWidth);
    }, [allCardsWidth]);

    useLayoutEffect(() => {
      switchCarouselOrPlainBlock();
    }, [switchCarouselOrPlainBlock]);

    useEffect(() => {
      window.addEventListener('resize', switchCarouselOrPlainBlock);
      return () => {
        window.removeEventListener('resize', switchCarouselOrPlainBlock);
      };
    }, [switchCarouselOrPlainBlock]);

    const actionTypeParams = (slug: string, documentId?: string) =>
      createActionTypeParams(navigate, setSearchParams, slug, documentId);

    const renderSlides = (items?: MainPageAction[]) => {
      if (!items) return [];

      return [
        items?.map(item => (
          <RecentlyViewedItem key={item.time_stamp} actionTypeParams={actionTypeParams} recentlyViewedItem={item} />
        )),
        <CreateNewProjectButton key="project-create-button" />,
      ];
    };

    const yPadding = parseInt(spacing(PADDING_TOP)) + parseInt(spacing(PADDING_BOTTOM));
    const xPadding = parseInt(spacing(PADDING_X * 2));

    return (
      <Box
        ref={recentlyViewedRef}
        sx={{
          '.slick-slide': {
            width: CARD_WIDTH + xPadding,
            height: CARD_HEIGHT + yPadding,
            p: spacing(PADDING_TOP, PADDING_X, PADDING_BOTTOM),
          },
          '.slider-arrows': {
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
          },
          '&:hover': {
            '.slider-arrows': {
              opacity: 1,
            },
          },
        }}
      >
        {isRecentViewedCarousel ? (
          <Carousel arrowPosition={-25} slidesToShow={5} responsive={responsive}>
            {renderSlides(recentlyViewedItems)}
          </Carousel>
        ) : (
          <Stack flexDirection="row" gap={3}>
            {renderSlides(initialCards)}
          </Stack>
        )}
      </Box>
    );
  },
  () => true,
);

export default RecentlyViewed;
