import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/hooks/useOrganization';
import { useEffect } from 'react';
import { usePageTitle } from '@/hooks/usePageTitle';
import Pages from '@/components/Pages/Pages';

const OrgAutomatePages = () => {
  const { t } = useTranslation('project');
  const { organization } = useOrganization();
  const { setPageTitle: setMainPageTitle } = usePageTitle();

  useEffect(() => {
    setMainPageTitle(t('pages.mainPageTitle'));
  }, []);

  return <Pages slug={organization.name} width="50%" minMaxHeight={170} />;
};

export default OrgAutomatePages;
