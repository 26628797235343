import { SliderThumb as MuiSliderThumb } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { FC, HTMLAttributes } from 'react';
import { PageActionType } from '@/api/generated';

interface SliderThumbProps extends HTMLAttributes<unknown> {
  type: PageActionType;
}

const SliderThumb: FC<SliderThumbProps> = ({ children, type, ...other }) => (
  <MuiSliderThumb {...other}>
    {children}
    <Icon name={type === PageActionType.change_page_length ? 'paragraphAdjustment' : 'reading'} fontSize="small" />
  </MuiSliderThumb>
);

export default SliderThumb;
