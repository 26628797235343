import { Shadows } from '@mui/material';

export default [
  'none',
  '0 0 16px 0 rgba(197, 197, 197, 0.80)',
  '0 4px 12px 0 #D0D0D0',
  '0 4.093px 16.372px 0 #D0D0D0',
  '0px 4.093px 20px 0px #C3C3C3',
  '0px 0px 5px 1px #5209e6',
  '5px 4px 16px 0 #ECEBEB',
  '-17px 1px 5px -8px #fff inset',
  '-17px 1px 5px -8px #f5f5f5 inset',
  '-0.47px 0.24px 1.42px 0px rgba(0, 0, 0, 0.25)',
  '-17px 1px 5px -8px #fff',
  '3px 5px 5px 0px rgba(139,137,134,0.5)',
  '0 -0.48px 12.16px -4px #A884F3',
  '0 2px 12.16px 0 #A884F3',
  '0 1.14px 9.12px 0 rgba(168,132,243,0.5)',
  '0px 0px 8px 0px #C5C5C5',
  '0px 4px 16px 0 rgba(197, 197, 197, 0.80)',

  ...Array(20).fill('none'),
] as Shadows;
