import { FC, useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { createPortal } from 'react-dom';
import Placeholder from '@/containers/PagesEditor/plugins/PlaceholderPlugin/Placeholder';
import { $getEditorTextUpToAnchor } from '@/containers/PagesEditor/utils/getTextUpToAnchor';
import { mergeRegister } from '@lexical/utils';
import { $isEditorEmpty } from '@/containers/PagesEditor/utils/$isEditorEmpty';
import { EDITOR_CONTENT_LEFT_PADDING } from '@/containers/PagesEditor/constants';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import { $getSelection, $isParagraphNode, $isTextNode } from 'lexical';
import { getDeepestParagraph } from '@/containers/PagesEditor/utils/getDeepestParagraph';

interface PlaceholderPluginProps {
  text: string;
  anchorElem?: HTMLElement;
}

const PlaceholderPlugin: FC<PlaceholderPluginProps> = ({ text, anchorElem = document.body }) => {
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();

  const [top, setTop] = useState(0);
  const [isNewAndEmptyLine, setIsNewAndEmptyLine] = useState(false);

  const $updateLocation = useCallback(() => {
    if ($isEditorEmpty()) {
      setTop(0);
      setIsNewAndEmptyLine(true);
      return;
    }

    const selection = $getSelection();
    if (selection && selection.isCollapsed()) {
      const [fistNode] = selection.getNodes();
      const deepestNode = $isParagraphNode(fistNode) ? getDeepestParagraph(fistNode)?.getFirstChild() : fistNode;
      if (deepestNode && !$isTextNode(deepestNode)) {
        setIsNewAndEmptyLine(false);
        return;
      }
    }

    const nativeSelection = window.getSelection();
    const selectionElem = nativeSelection?.anchorNode?.firstChild?.parentElement;
    if (!selectionElem || !anchorElem || $getEditorTextUpToAnchor() !== null) {
      setIsNewAndEmptyLine(false);
      return;
    }

    const selectionElemRect = selectionElem.getBoundingClientRect();
    const anchorElementRect = anchorElem.getBoundingClientRect();
    const left = selectionElemRect.left - anchorElementRect.left;
    // We want to show placeholder only for root elements.
    setIsNewAndEmptyLine(left <= EDITOR_CONTENT_LEFT_PADDING);
    setTop(selectionElemRect.top - anchorElementRect.top);
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(() => editor.getEditorState().read(() => $updateLocation())),
        editor.registerRootListener(() => editor.getEditorState().read(() => $updateLocation())),
      ),
    [$updateLocation],
  );

  if (!isNewAndEmptyLine || !isEditable) return null;

  return createPortal(<Placeholder top={top}>{text}</Placeholder>, anchorElem);
};

export default PlaceholderPlugin;
