import { IconButton, Stack, useTheme } from '@mui/material';
import { ChangeEvent, FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/Icon/Icon';
import Tabs, { TabsValues } from '@/views/Knowledge/components/Tabs';
import CustomCheckbox from '@/views/Knowledge/components/CustomCheckbox';
import { TABS } from '@/views/Knowledge/components/consts';

interface HeaderProps {
  handleTabChange: (event: SyntheticEvent, newValue: TABS) => void;
  setIsWeb: (isWeb: boolean) => void;
  isWeb: boolean;
  createNewThread: () => void;
  onPopoverClose: () => void;
  onToggleExpandPopover: () => void;
  isExpanded: boolean;
}

const tabs: TabsValues[] = [
  { iconName: 'chat', value: TABS.CHAT },
  { iconName: 'history', value: TABS.THREADS },
  { iconName: 'file', value: TABS.KNOWLEDGE_BASE },
];

const Header: FC<HeaderProps> = ({
  handleTabChange,
  setIsWeb,
  isWeb,
  createNewThread,
  onPopoverClose,
  onToggleExpandPopover,
  isExpanded,
}) => {
  const { t } = useTranslation('common');
  const { spacing, palette } = useTheme();

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => setIsWeb(event.target.checked);

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className="draggable-header"
      sx={{
        borderBottom: 1,
        borderColor: palette.grey[500],
        p: spacing(4.5, 2, 2.5, 2),
        mt: 0.5,
        position: 'sticky',
        top: 0,
        backgroundColor: palette.background.default,
        zIndex: 1,
        cursor: isExpanded ? 'default' : 'grab',
      }}
    >
      <Tabs tabs={tabs} handleTabChange={handleTabChange} />
      <Stack flexDirection="row" alignItems="center" gap={1.5} sx={{ flex: '1 0 auto' }}>
        <CustomCheckbox checked={isWeb} onChange={handleToggle} label={t('knowledge.toggle.web')} />
        <IconButton sx={{ p: 0.5 }} color="primary" size="small" onClick={createNewThread}>
          <Icon name="newThread" fontSize="small" />
        </IconButton>
      </Stack>
      <Stack
        flexDirection="row"
        alignItems="center"
        sx={{ position: 'absolute', right: 0, top: 0, m: spacing(0.75, 2), gap: 1.25 }}
      >
        <IconButton
          color="primary"
          size="small"
          sx={{ p: 0.5, svg: { fontSize: 'body4.fontSize' } }}
          onClick={onToggleExpandPopover}
        >
          <Icon name={isExpanded ? 'minimize' : 'expand'} fontSize="small" />
        </IconButton>

        <IconButton color="primary" size="small" sx={{ p: 0.5, svg: { fontSize: 'body4.fontSize' } }} onClick={onPopoverClose}>
          <Icon name="x" fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Header;
