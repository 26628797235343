import { $getSelection, $isRangeSelection, RangeSelection } from 'lexical';

export const getTextUpToAnchor = (selection: RangeSelection) => {
  const anchor = selection.anchor;
  if (anchor.type !== 'text') return null;

  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) return null;

  const anchorOffset = anchor.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
};

export const $getEditorTextUpToAnchor = () => {
  const range = document.createRange();
  const selection = $getSelection();

  if (!selection || !$isRangeSelection(selection) || !selection.isCollapsed() || range === null) return;

  return getTextUpToAnchor(selection);
};
