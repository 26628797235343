import { LexicalEditor } from 'lexical';
import { INSERT_TABLE_COMMAND } from '@lexical/table';
import { ComponentPickerOption } from '@/containers/PagesEditor/plugins/ComponentPickerPlugin/components/ComponentPickerOption';
import Icon from '@/components/Icon/Icon';

const getDynamicOptions = (editor: LexicalEditor, queryString: string) => {
  const options: Array<ComponentPickerOption> = [];

  if (queryString == null) {
    return options;
  }

  const tableMatch = queryString.match(/^([1-9]\d?)(?:x([1-9]\d?)?)?$/);

  if (tableMatch !== null) {
    const rows = tableMatch[1];
    const colOptions = tableMatch[2] ? [tableMatch[2]] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(String);

    options.push(
      ...colOptions.map(
        columns =>
          new ComponentPickerOption(
            { title: `${rows}x${columns} Table` },
            {
              icon: <Icon sx={{ mr: 1.25, width: 16 }} name="table" />,
              keywords: ['table'],
              onSelect: () => editor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows }),
            },
          ),
      ),
    );
  }

  return options;
};

export default getDynamicOptions;
