import { FC } from 'react';
import { ChangeableBlockType } from '@/containers/PagesEditor/plugins/FloatingTextFormatToolbarPlugin/constants';
import Icon, { IconNames, IconProps } from '@/components/Icon/Icon';

interface PageEditorIconProps extends Omit<IconProps, 'name'> {
  blockType: keyof typeof ICONS_NAME_TO_COMPONENT_MAP;
}

const ICONS_NAME_TO_COMPONENT_MAP: Partial<Record<ChangeableBlockType, IconNames>> = {
  paragraph: 'text',
  number: 'numberedList',
  bullet: 'bulletList',
  check: 'smallChecklist',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  quote: 'quote',
  code: 'code',
};

const PageEditorIcon: FC<PageEditorIconProps> = ({ blockType, ...props }) => {
  const iconName = ICONS_NAME_TO_COMPONENT_MAP[blockType];

  if (!iconName) return null;

  return <Icon name={iconName} {...props} />;
};

export default PageEditorIcon;
