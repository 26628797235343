import { FC, ReactNode } from 'react';
import Slider, { Settings } from 'react-slick';
import CustomArrow from '@/components/Carousel/CustomArrow';
import { DIRECTIONS } from '@/components/Carousel/consts';
import { SxProps } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CarouselProps {
  sx?: SxProps;
  children: ReactNode;
  arrowPosition?: number;
  slidesToShow: Settings['slidesToShow'];
  responsive?: Settings['responsive'];
}

const Carousel: FC<CarouselProps> = ({ children, sx, arrowPosition, slidesToShow, responsive }) => (
  <Slider
    infinite={false}
    slidesToShow={slidesToShow}
    slidesToScroll={1}
    variableWidth
    arrows
    nextArrow={<CustomArrow sx={sx} direction={DIRECTIONS.NEXT} arrowPosition={arrowPosition} />}
    prevArrow={<CustomArrow sx={sx} direction={DIRECTIONS.PREV} arrowPosition={arrowPosition} />}
    dots={false}
    responsive={responsive}
  >
    {children}
  </Slider>
);

export default Carousel;
