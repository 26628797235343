import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CircularProgress, ClickAwayListener, Paper, Stack, useTheme } from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createRangeSelection,
  $getSelection,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  createCommand,
  KEY_SPACE_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { Key } from 'ts-key-enum';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import AiPopup from '@/containers/PagesEditor/plugins/AskAiPlugin/components/AiPopup';
import nextTick from '@/services/nextTick';
import AiResponseReview from '@/components/AiResponseReview';
import AiMenu from '@/components/AiMenu';
import AiMenuItem from '@/components/AiMenuItem';
import useFakeSelection from '@/containers/PagesEditor/hooks/useFakeSelection';
import Icon from '@/components/Icon/Icon';
import { $getEditorTextUpToAnchor } from '@/containers/PagesEditor/utils/getTextUpToAnchor';
import { $createElementNodeFromMarkdown } from '@/containers/PagesEditor/utils/$createElementNodeFromMarkdown';
import { MentionType, Question } from '@/api/generated';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import AiMentionsInput from '@/components/AiMentionsInput';
import { ejectMentionsFromText } from '@/utils/ejectMentionsFromText';
import { AskAiFn } from '@/containers/PagesEditor/types';
import { SuggestionDataItem } from '@/utils/getMentionTypeOptions';

interface askAiPluginProps {
  anchorElem: HTMLElement;
  askAi: AskAiFn;
  getSuggestions: (selectedText?: string) => Promise<Question[]>;
  getMentions: (type: MentionType) => SuggestionDataItem[];
  width: string | number;
  minMaxHeight: number;
}

export const ASK_AI_COMMAND = createCommand<{ isAskingWithSelection?: boolean }>();

const POPUP_CLASS_NAME = 'ai-plugin-popup';

const AskAiPlugin: FC<askAiPluginProps> = ({ anchorElem, askAi, getSuggestions, getMentions, width, minMaxHeight }) => {
  const { t } = useTranslation('project');
  const [editor] = useLexicalComposerContext();
  const { palette, spacing } = useTheme();

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const rangeRef = useRef<Range>();
  const isAskingWithSelectionRef = useRef(false);
  const [aiText, setAiText] = useState<string | null>(null);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState('');
  const [suggestions, setSuggestions] = useState<Question[]>([]);
  const [suggestionsIsLoading, setSuggestionsIsLoading] = useState(false);
  const lastAIRequestPromise = useRef<Promise<string> | null>(null);
  const { showConfirmDialog } = useConfirmDialog();
  const fakeSelection = useFakeSelection(editor);

  const reset = useCallback(() => {
    setText('');
    setIsVisible(false);
    setAiText(null);
    setIsAiLoading(false);
    fakeSelection.reset();
    rangeRef.current = undefined;
    lastAIRequestPromise.current?.cancel();
  }, [fakeSelection]);

  const getReferenceText = () => rangeRef.current?.toString();

  const returnNativeSelection = () => {
    const nativeSelection = window.getSelection();
    if (!rangeRef.current || !nativeSelection) return;

    nativeSelection.removeAllRanges();
    nativeSelection.addRange(rangeRef.current);
  };

  const askBeforeReset = async () => {
    if (!aiText) return reset();

    const result = await showConfirmDialog({
      title: t('pages.editor.ai.discardConfirm.title'),
      description: t('pages.editor.ai.discardConfirm.description'),
      confirm: t('pages.editor.ai.discardConfirm.confirm'),
    });
    if (result) reset();
  };

  const requestAi: AskAiFn = async (question, params) => {
    lastAIRequestPromise.current?.cancel();
    setIsAiLoading(true);

    lastAIRequestPromise.current = askAi(question, params);

    try {
      const savedAiText = await lastAIRequestPromise.current;
      setIsAiLoading(false);
      return savedAiText;
    } catch (error) {
      setIsAiLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code !== Key.Escape || !isVisible) return;

      returnNativeSelection();
      askBeforeReset();
    };

    const handleMouseDown = () => {
      askBeforeReset();
    };

    const rootElement = editor.getRootElement();
    rootElement?.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      rootElement?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [editor, reset, aiText, isVisible]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          KEY_SPACE_COMMAND,
          event => {
            if ($getEditorTextUpToAnchor() !== null) return false;

            event.preventDefault();
            return editor.dispatchCommand(ASK_AI_COMMAND, { isAskingWithSelection: false });
          },
          COMMAND_PRIORITY_EDITOR,
        ),
        editor.registerCommand(
          ASK_AI_COMMAND,
          ({ isAskingWithSelection }) => {
            isAskingWithSelectionRef.current = Boolean(isAskingWithSelection);
            rangeRef.current = window.getSelection()?.getRangeAt(0);
            if (isAskingWithSelection && rangeRef.current) fakeSelection.calculatePositions();
            (async () => {
              setSuggestionsIsLoading(true);
              try {
                const selection = $getSelection();
                const selectedText = selection?.getTextContent();
                const allSuggestion = await getSuggestions(selectedText);
                setSuggestions(allSuggestion);
                setSuggestionsIsLoading(false);
              } catch {
                console.error('Fetch was failed');
                setSuggestionsIsLoading(false);
              }
            })();

            setIsVisible(true);
            nextTick(() => {
              inputRef.current?.focus();
              isAskingWithSelectionRef.current && fakeSelection.add();
            });
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, fakeSelection],
  );

  const putAiTextIntoEditor = (textFromAi: string) => {
    editor.update(() => {
      if (!rangeRef.current) return;

      const newParagraphNode = $createElementNodeFromMarkdown(textFromAi);
      const selection = $createRangeSelection();
      selection.applyDOMRange(rangeRef.current);
      $setSelection(selection);
      selection.insertNodes(newParagraphNode.getChildren());
    });
    reset();
  };

  const onSubmit = async () => {
    try {
      const reference = getReferenceText();
      const { text: simplifiedQuestion, mentions } = ejectMentionsFromText(text);
      const textFromAi = await requestAi(simplifiedQuestion, { reference, mentions });

      if (reference) {
        setAiText(textFromAi);
        setText('');
      } else {
        putAiTextIntoEditor(textFromAi);
      }
    } catch (error) {
      enqueueSnackbar(t('pages.editor.ai.requestError'), { variant: 'error' });
    }
  };

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest(`.${POPUP_CLASS_NAME}`)) return;

    askBeforeReset();
  };

  const onReplace = () => {
    aiText && putAiTextIntoEditor(aiText);
  };

  const onDiscard = () => {
    askBeforeReset();
  };

  const onInsertBelow = () => {
    editor.update(() => {
      if (!rangeRef.current || !aiText) return;

      const newParagraphNode = $createElementNodeFromMarkdown(aiText);
      const selection = $createRangeSelection();
      selection.applyDOMRange(rangeRef.current);

      const nodes = selection.getNodes();
      const lastNode = nodes[nodes.length - 1];
      const topElement = lastNode.getTopLevelElementOrThrow();

      newParagraphNode
        .getChildren()
        .reverse()
        .forEach(child => topElement.insertAfter(child));
    });
    reset();
  };

  if (!isVisible) return;

  const isTextSelected = rangeRef.current && rangeRef.current.startOffset !== rangeRef.current.endOffset;

  return createPortal(
    <ClickAwayListener onClickAway={onClickAway}>
      <AiPopup className={POPUP_CLASS_NAME} editor={editor} anchorElem={anchorElem} width={width} onClose={askBeforeReset}>
        <Stack gap={1}>
          {aiText && <AiResponseReview aiText={aiText} />}
          <Paper sx={{ borderRadius: 999, boxShadow: 2 }}>
            <AiMentionsInput
              ref={inputRef}
              placeholder={isTextSelected ? t('ai.editOrWritePlaceholder') : t('ai.writePlaceholder')}
              value={text}
              isSubmitting={isAiLoading}
              onChange={setText}
              onSubmit={onSubmit}
              documents={getMentions(MentionType.document)}
              tools={getMentions(MentionType.tool)}
              minMaxHeight={minMaxHeight}
            />
          </Paper>
          {aiText ? (
            <AiMenu>
              <AiMenuItem onClick={onReplace}>
                <Icon name="checkmark" htmlColor={palette.primary.dark} />
                {t('pages.editor.ai.replace')}
              </AiMenuItem>
              <AiMenuItem onClick={onInsertBelow}>
                <Icon name="afterParagraph" htmlColor={palette.primary.dark} />
                {t('pages.editor.ai.insertBelow')}
              </AiMenuItem>
              <AiMenuItem onClick={onDiscard}>
                <Icon name="bin" htmlColor={palette.primary.dark} />
                {t('pages.editor.ai.discard')}
              </AiMenuItem>
            </AiMenu>
          ) : (
            !isAiLoading && (
              <AiMenu sx={{ minWidth: 310, color: palette.grey[800] }}>
                {suggestionsIsLoading ? (
                  <CircularProgress sx={{ alignSelf: 'center', width: '30px !important', height: '30px !important', my: 1 }} />
                ) : (
                  suggestions.map(({ text: suggestionText }) => (
                    <AiMenuItem
                      key={suggestionText}
                      onClick={() => {
                        setText(suggestionText);
                        inputRef.current?.focus();
                      }}
                      sx={{ '&.MuiMenuItem-root': { p: spacing(1.5, 3) } }}
                    >
                      <Icon name="ai" htmlColor={palette.primary.dark} fontSize="xsmall" />
                      {suggestionText}
                    </AiMenuItem>
                  ))
                )}
              </AiMenu>
            )
          )}
        </Stack>
      </AiPopup>
    </ClickAwayListener>,
    anchorElem,
  );
};

export default AskAiPlugin;
