import { exportToSvg, exportToCanvas } from '@excalidraw/excalidraw';
import { FC, useEffect, useState } from 'react';
import { ExcalidrawNode } from '@/containers/PagesEditor/nodes/ExcalidrawNode';
import { Image } from '@react-pdf/renderer';

interface ExcalidrawImageProps {
  node: ExcalidrawNode;
}

const SCALE_TO_DOCUMENT_FONT = 0.9;

const ExcalidrawPdfImage: FC<ExcalidrawImageProps> = ({ node }) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    (async () => {
      const json = node.exportJSON();
      const excalidrawData = JSON.parse(json.data);
      const canvas = await exportToCanvas(excalidrawData);

      const widthToSet = json.width === 'inherit' ? 300 : json.width;
      if (json.height === 'inherit') {
        const svg = await exportToSvg(excalidrawData);
        const proportion = svg.height.baseVal.value / svg.width.baseVal.value;
        setHeight(widthToSet * proportion * SCALE_TO_DOCUMENT_FONT);
      } else {
        setHeight(json.height * SCALE_TO_DOCUMENT_FONT);
      }

      setWidth(widthToSet * SCALE_TO_DOCUMENT_FONT);
      setSrc(canvas.toDataURL());
    })();
  }, [node]);

  return <Image src={src} style={{ width, height }} />;
};

export default ExcalidrawPdfImage;
