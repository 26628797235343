import { Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import App from '@/views/Projects/components/Apps/App';
import { useProjectFormDialog } from '@/hooks/useProjectFormDialog';
import { Link } from 'react-router-dom';
import { toOrgKb, toOrgPages } from '@/services/linker';
import Icon from '@/components/Icon/Icon';

const Apps = () => {
  const { t } = useTranslation('projects');
  const { showProjectFormDialog } = useProjectFormDialog();
  const { spacing, palette } = useTheme();

  return (
    <Stack
      flexDirection="column"
      sx={{
        gap: 1.25,
        justifyContent: 'center',
        boxShadow: 16,
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 3,
        position: 'fixed',
        right: 35,
        top: '50%',
        transform: 'translateY(-50%)',
        p: spacing(2, 0),
        zIndex: 1,
        backgroundColor: palette.background.default,
      }}
    >
      <Stack
        gap={1}
        alignItems="center"
        sx={{ borderBottom: `1px solid ${palette.grey[200]}`, width: '100%', pb: 2, maxWidth: 55 }}
      >
        <Icon name="apps" fontSize="large" htmlColor={palette.purple.dark} />
        <Typography sx={{ fontSize: 'body3.fontSize', color: palette.grey['A200'] }}>Apps</Typography>
      </Stack>
      <App label={t('apps.compare')} iconName="compare" onClick={() => showProjectFormDialog()} isEnabled={false} />
      <App label={t('apps.canvas')} iconName="ai" to={toOrgPages()} component={Link} isEnabled={true} reloadDocument />
      <App label={t('apps.integration')} iconName="connectivity" onClick={() => showProjectFormDialog()} isEnabled={false} />
      <App label={t('apps.orgDatabase')} iconName="orgKb" component={Link} to={toOrgKb()} isEnabled={true} reloadDocument />
    </Stack>
  );
};

export default Apps;
