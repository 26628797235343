import { FC, useMemo } from 'react';
import { Box, BoxProps } from '@mui/material';
import { convertMarkdownToHtmlSafely } from '@/utils/convertMarkdownToHtmlSafely';

interface AiResponseReviewProps extends BoxProps {
  markdown?: string | null;
}

const MarkdownText: FC<AiResponseReviewProps> = ({ markdown, sx, ...props }) => {
  const aiTextHtml = useMemo(() => (markdown ? convertMarkdownToHtmlSafely(markdown) : ''), [markdown]);

  return (
    <Box
      sx={{
        fontSize: 'body2.fontSize',
        '& p': { m: 0 },
        '& table, tr, th, td': { borderCollapse: 'collapse', border: '1px solid #bbb' },
        '& th': { backgroundColor: '#f2f3f5' },
        '& th, td': { p: 1 },
        ...sx,
      }}
      dangerouslySetInnerHTML={{ __html: aiTextHtml }}
      {...props}
    />
  );
};

export default MarkdownText;
