import { FC } from 'react';
import { ListNode, ListItemNode } from '@lexical/list';
import { View } from '@react-pdf/renderer';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';
import ListItemMark from '@/components/LexicalPdfExport/components/ListItemMark';

interface PdfListProps {
  node: ListNode;
}

const PdfListView: FC<PdfListProps> = ({ node }) => (
  <View style={{ gap: 4, paddingLeft: 16, paddingVertical: 2 }}>
    {node.getChildren<ListItemNode>().map((listItem, index) => (
      <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start' }}>
        <ListItemMark index={index} type={node.getListType()} checked={listItem.getChecked()} />
        <LexicalNodePdfView nodes={listItem.getChildren()} />
      </View>
    ))}
  </View>
);

export default PdfListView;
