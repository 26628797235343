export const getFadingShadowSxProps = (shadow: number) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    boxShadow: shadow,
  },
});
