import { FC } from 'react';
import { Document, Page, Text, Image, View } from '@react-pdf/renderer';
import { LexicalNode } from 'lexical';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';

interface PdfExportProps {
  title: string;
  nodes: LexicalNode[];
  logoBase64: string;
  logoProportions: number;
}

const MAX_WIDTH = 80;
const MAX_HEIGHT = 50;

const LexicalPdfExport: FC<PdfExportProps> = ({ title, nodes, logoBase64, logoProportions }) => {
  const width = logoProportions > 1 ? MAX_WIDTH : MAX_HEIGHT * logoProportions;
  const height = logoProportions > 1 ? MAX_WIDTH / logoProportions : MAX_HEIGHT;

  return (
    <Document style={{ fontSize: 14 }}>
      <Page size="A4" style={{ padding: '0.75in' }}>
        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '-0.5in',
            marginRight: '-0.5in',
            paddingBottom: '0.25in',
          }}
        >
          <Image src={logoBase64} style={{ width, height }} />
        </View>
        <Text style={{ marginBottom: 20, fontSize: 34, fontWeight: 'bold' }}>{title}</Text>
        <LexicalNodePdfView nodes={nodes} />
      </Page>
    </Document>
  );
};

export default LexicalPdfExport;
