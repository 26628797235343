import { forwardRef, MouseEvent, ReactElement } from 'react';
import { Fab, FabProps, SvgIconProps } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';

export interface FabButtonProps extends FabProps {
  isDisabled?: boolean;
  iconName?: IconNames;
  children?: ReactElement<SvgIconProps>;
  onClick: (event: MouseEvent) => void;
}

const FabButton = forwardRef<HTMLButtonElement, FabButtonProps>(({ isDisabled, iconName, children, onClick, ...props }, ref) => (
  <Fab {...props} ref={ref} size="small" disabled={isDisabled} onClick={onClick}>
    {children ?? <Icon name={iconName!} />}
  </Fab>
));

export default FabButton;
