import { FC, ReactNode } from 'react';
import { ClickAwayListener, Collapse, Paper, SxProps } from '@mui/material';
import { isEventTargetConfirmDialog } from '@/utils/isEventTargetConfirmDialog';
import nextTick from '@/services/nextTick';
import { isEventTargetDrawerToggle } from '@/utils/isEventTargetDrawerToggle';

interface DrawerProps {
  sx?: SxProps;
  isOpened: boolean;
  children: ReactNode;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = ({ sx, isOpened, children, onClose }) => {
  const onClickAway = (event: MouseEvent | TouchEvent) => {
    const target = event.target as HTMLElement;
    if (isEventTargetConfirmDialog(target) || isEventTargetDrawerToggle(target)) return;

    // Tick makes second ClickAwayListener inside TreePageNode input work.
    nextTick(() => onClose());
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Paper sx={{ position: 'absolute', zIndex: 2, overflow: 'hidden', ...sx }}>
        <Collapse orientation="horizontal" in={isOpened}>
          {children}
        </Collapse>
      </Paper>
    </ClickAwayListener>
  );
};

export default Drawer;
