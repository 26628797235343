import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks/useAuth';

export const useGreetingMessage = () => {
  const { t } = useTranslation('project');
  const currentHour = Number(format(new Date(), 'H'));
  const { currentUser: { firstName } = {} } = useAuth();

  if (currentHour >= 5 && currentHour < 12) {
    return t('homepage.initialText.header.morning', { user: firstName });
  } else if (currentHour >= 12 && currentHour < 18) {
    return t('homepage.initialText.header.afternoon', { user: firstName });
  } else if (currentHour >= 18 && currentHour < 21) {
    return t('homepage.initialText.header.evening', { user: firstName });
  } else {
    return t('homepage.initialText.header.night', { user: firstName });
  }
};
