import { forwardRef } from 'react';
import { InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';
import SearchIcon from '@/assets/search-icon.svg?react';
import { useTranslation } from 'react-i18next';

type SearchInputProps = TextFieldProps & {
  isOpen: boolean;
};

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({ isOpen, ...props }, ref) => {
  const { t } = useTranslation('editor');
  const { palette } = useTheme();

  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="standard"
      autoComplete="off"
      autoFocus
      type="search"
      placeholder={t('documents.search')}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" sx={{ m: 1, width: isOpen ? 'auto' : 0 }}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      inputProps={{
        sx: {
          '&::placeholder': { color: palette.grey[800], opacity: 1, lineHeight: 2 },
          '&::-webkit-search-cancel-button': {
            display: 'none',
          },
        },
      }}
      sx={{
        width: isOpen ? '100%' : 0,
        height: '100%',
        borderTopRightRadius: 999,
        borderBottomRightRadius: 999,
        '.MuiInputBase-input': { py: 0 },
        '.MuiInputBase-root': { height: '100%', backgroundColor: 'transparent' },
      }}
    />
  );
});

export default SearchInput;
