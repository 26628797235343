import { $getRoot, ParagraphNode } from 'lexical';

export const $isEditorEmpty = () => {
  const root = $getRoot();

  if (root.isEmpty()) return true;

  const firstChild = root.getFirstChild<ParagraphNode>();

  return !firstChild || (firstChild.isEmpty?.() && root.getChildrenSize() === 1);
};
