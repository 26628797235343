import { Button, InputAdornment, Stack, SxProps, useTheme } from '@mui/material';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateHandler,
  DeleteHandler,
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  RenameHandler,
  Tree,
  TreeApi,
} from 'react-arborist';
import { useResizeObserver } from 'usehooks-ts';
import TreeDocumentNode from '@/views/Projects/components/ProjectFormDialog/components/TreeDocumentNode';
import Icon from '@/components/Icon/Icon';
import { TreeFileSystemNode } from '@/types';
import RoundedInput from '@/components/RoundedInput';

interface FilesTreeProps {
  sx?: SxProps;
  fileSystemNodes: TreeFileSystemNode[];
  onRename: RenameHandler<TreeFileSystemNode>;
  onMove: MoveHandler<TreeFileSystemNode>;
  onFolderCreate: CreateHandler<TreeFileSystemNode>;
  onDelete: DeleteHandler<TreeFileSystemNode>;
  onFileOpen: (documentId: string) => void;
  onAddVersion: (node: NodeApi<TreeFileSystemNode>) => void;
  onDocumentConvertToPage: (documentId: string) => void;
}

const FilesTree: FC<FilesTreeProps> = ({
  sx,
  fileSystemNodes,
  onRename,
  onMove,
  onFolderCreate,
  onDelete,
  onFileOpen,
  onAddVersion,
  onDocumentConvertToPage,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette, spacing } = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const treeRef = useRef<TreeApi<TreeFileSystemNode> | null>(null);
  const folderIdRef = useRef<string>();
  const treeWrapRef = useRef<HTMLDivElement | null>(null);
  const { width = 0, height = 0 } = useResizeObserver({
    ref: treeWrapRef,
    box: 'border-box',
  });

  const onAddFile = useCallback((node: NodeApi<TreeFileSystemNode>) => {
    folderIdRef.current = node.id;
    open();
  }, []);

  const handleFileOpen = useCallback((node: NodeApi<TreeFileSystemNode>) => onFileOpen(node.id), []);

  const TreeDocumentNodeWrap = useCallback(
    (nodeProps: NodeRendererProps<TreeFileSystemNode>) => (
      <TreeDocumentNode
        {...nodeProps}
        onAddFile={onAddFile}
        onFileOpen={handleFileOpen}
        onAddVersion={onAddVersion}
        onDocumentConvertToPage={onDocumentConvertToPage}
      />
    ),
    [onAddFile, onFileOpen, onAddVersion, onDocumentConvertToPage],
  );

  return (
    <Stack
      sx={{
        ...sx,
        minHeight: 0,
        height: '100%',
        overflow: 'hidden',
        borderRadius: 4,
        boxShadow: 6,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={0.5} sx={{ width: '100%', px: 3, pt: 2, pb: 1 }}>
        <RoundedInput
          sx={{
            width: 150,
            height: 24,
            px: 0.5,
            '.MuiInputBase-root': { p: 0, height: '100%' },
            '.MuiInputBase-input': { px: 0, py: 0.3, fontSize: 'body2.fontSize' },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ color: palette.grey[700] }} position="start">
                <Icon name="search" fontSize="small" sx={{ color: palette.darkPurple.dark }} />
              </InputAdornment>
            ),
          }}
          placeholder={t('uploadFiles.searchPlaceholder')}
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />

        <Button
          title={t('uploadFiles.addFolder')}
          variant="contained"
          size="small"
          color="accent"
          sx={{ display: 'flex', gap: 0.5, alignItems: 'center', px: 1.5, height: 24, borderRadius: 999 }}
          onClick={() => treeRef.current?.create()}
        >
          <Icon name="folder" fontSize="small" />
          {t('uploadFiles.newFolder')}
        </Button>
      </Stack>

      <Stack
        ref={treeWrapRef}
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: 0,
          height: '100%',
          '& > div > div': { scrollbarWidth: 'thin' },
          '& [role="treeitem"]': { outline: 'none' },
        }}
      >
        {!fileSystemNodes.length && (
          <Stack
            sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, justifyContent: 'center', textAlign: 'center' }}
          >
            {t('uploadFiles.noFiles')}
          </Stack>
        )}
        <Tree
          ref={treeRef}
          width={width}
          height={height}
          openByDefault={false}
          disableMultiSelection
          paddingBottom={parseInt(spacing(2))}
          rowHeight={34}
          searchTerm={searchTerm}
          searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}
          idAccessor={node => node.id}
          disableDrop={({ parentNode }) => !parentNode.isRoot && parentNode.data.type !== 'folder'}
          childrenAccessor="children"
          onMove={onMove}
          onCreate={onFolderCreate}
          onRename={onRename}
          onDelete={onDelete}
          data={fileSystemNodes}
        >
          {TreeDocumentNodeWrap}
        </Tree>
      </Stack>
    </Stack>
  );
};

export default FilesTree;
