import { $createParagraphNode, $getSelection, $isRangeSelection, FORMAT_ELEMENT_COMMAND, LexicalEditor } from 'lexical';
import { ComponentPickerOption } from '@/containers/PagesEditor/plugins/ComponentPickerPlugin/components/ComponentPickerOption';
import { $setBlocksType } from '@lexical/selection';
import { $createCodeNode } from '@lexical/code';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list';
import Icon from '@/components/Icon/Icon';
import { INSERT_COLLAPSIBLE_COMMAND } from '@lexical/playground/plugins/CollapsiblePlugin';
import { InsertTableDialog } from '@lexical/playground/plugins/TablePlugin';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { INSERT_PAGE_BREAK } from '@lexical/playground/plugins/PageBreakPlugin';
import { INSERT_EXCALIDRAW_COMMAND } from '@/containers/PagesEditor/plugins/ExcalidrawPlugin';
import InsertLayoutDialog from '@lexical/playground/plugins/LayoutPlugin/InsertLayoutDialog';
import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin';
import useModal from '@lexical/playground/hooks/useModal';
import { TwitterEmbedConfig, YoutubeEmbedConfig } from '@lexical/playground/plugins/AutoEmbedPlugin';

type ShowModal = ReturnType<typeof useModal>[1];

const commonIconStyles = { mr: 1.25, width: 16 };

const customEmbedConfigs = [
  {
    ...TwitterEmbedConfig,
    contentName: 'Twitter',
    description: 'Embed a tweet',
    icon: <Icon sx={commonIconStyles} name="twitter" />,
  },
  { ...YoutubeEmbedConfig, description: 'Embed a YouTube video ', icon: <Icon sx={commonIconStyles} name="youtube" /> },
];

const getBaseOptions = (editor: LexicalEditor, showModal: ShowModal) => [
  new ComponentPickerOption(
    { title: 'Headings and Text' },
    {
      isGroupTitle: true,
      onSelect: () => {},
    },
  ),
  new ComponentPickerOption(
    { title: 'Text', subTitle: 'Used for the body of your document' },
    {
      icon: <Icon sx={commonIconStyles} name="text" />,
      keywords: ['normal', 'paragraph', 'p', 'text'],
      onSelect: () =>
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode());
          }
        }),
    },
  ),
  ...(
    [
      { number: 1, description: 'Used for a top-level heading' },
      { number: 2, description: 'Used for key sections' },
      { number: 3, description: 'Used for subsections and group headings' },
    ] as const
  ).map(
    n =>
      new ComponentPickerOption(
        { title: `Heading ${n.number}`, subTitle: n.description },
        {
          icon: <Icon sx={commonIconStyles} name={`h${n.number}`} />,
          keywords: ['heading', 'header', `h${n.number}`],
          onSelect: () =>
            editor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createHeadingNode(`h${n.number}`));
              }
            }),
        },
      ),
  ),
  ...(
    [
      { direction: 'left', description: 'Align text to the left' },
      { direction: 'center', description: 'Align text to the center' },
      { direction: 'right', description: 'Align text to the right' },
      { direction: 'justify', description: 'Align text with both margins' },
    ] as const
  ).map(
    alignment =>
      new ComponentPickerOption(
        { title: `Align ${alignment.direction}`, subTitle: alignment.description },
        {
          icon: <Icon sx={commonIconStyles} name={alignment.direction} />,
          keywords: ['align', 'justify', alignment.direction],
          onSelect: () => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignment.direction),
        },
      ),
  ),
  new ComponentPickerOption(
    { title: 'Basic blocks' },
    {
      isGroupTitle: true,
      onSelect: () => {},
    },
  ),
  new ComponentPickerOption(
    { title: 'Numbered List', subTitle: 'Used to display a numbered list' },
    {
      icon: <Icon sx={commonIconStyles} name="numberedList" />,
      keywords: ['numbered list', 'ordered list', 'ol'],
      onSelect: () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Bulleted List', subTitle: 'Used to display an unordered list' },
    {
      icon: <Icon sx={commonIconStyles} name="bulletList" />,
      keywords: ['bulleted list', 'unordered list', 'ul'],
      onSelect: () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Check List', subTitle: 'Used to display a list with checkboxes' },
    {
      icon: <Icon sx={commonIconStyles} name="smallChecklist" />,
      keywords: ['check list', 'todo list'],
      onSelect: () => editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Toggle List', subTitle: 'Toggales can hide and show content inside' },
    {
      icon: <Icon sx={commonIconStyles} name="collapse" />,
      keywords: ['collapse', 'collapsible', 'toggle'],
      onSelect: () => editor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Table', subTitle: 'Used for tables' },
    {
      icon: <Icon sx={commonIconStyles} name="table" />,
      keywords: ['table', 'grid', 'spreadsheet', 'rows', 'columns'],
      onSelect: () => showModal('Insert Table', onClose => <InsertTableDialog activeEditor={editor} onClose={onClose} />),
    },
  ),
  new ComponentPickerOption(
    { title: 'Quote', subTitle: 'Capture a quote' },
    {
      icon: <Icon sx={commonIconStyles} name="quote" />,
      keywords: ['block quote'],
      onSelect: () =>
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createQuoteNode());
          }
        }),
    },
  ),
  new ComponentPickerOption(
    { title: 'Divider', subTitle: 'Visually divide blocks' },
    {
      icon: <Icon sx={commonIconStyles} name="divider" />,
      keywords: ['horizontal rule', 'divider', 'hr'],
      onSelect: () => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Page Break', subTitle: 'Visually separate between pages' },
    {
      icon: <Icon sx={commonIconStyles} name="pageBreak" />,
      keywords: ['page break', 'divider'],
      onSelect: () => editor.dispatchCommand(INSERT_PAGE_BREAK, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Excalidraw', subTitle: 'Sketch diagrams on a whiteboard' },
    {
      icon: <Icon sx={commonIconStyles} name="excalidraw" />,
      keywords: ['excalidraw', 'diagram', 'drawing'],
      onSelect: () => editor.dispatchCommand(INSERT_EXCALIDRAW_COMMAND, undefined),
    },
  ),
  new ComponentPickerOption(
    { title: 'Columns Layout', subTitle: 'Create columns of blocks' },
    {
      icon: <Icon sx={commonIconStyles} name="columnsLayout" />,
      keywords: ['columns', 'layout', 'grid'],
      onSelect: () =>
        showModal('Insert Columns Layout', onClose => <InsertLayoutDialog activeEditor={editor} onClose={onClose} />),
    },
  ),
  new ComponentPickerOption(
    { title: 'Code', subTitle: 'Create columns of blocks' },
    {
      icon: <Icon sx={commonIconStyles} name="code" />,
      keywords: ['javascript', 'python', 'js', 'codeblock'],
      onSelect: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            if (selection.isCollapsed()) {
              $setBlocksType(selection, () => $createCodeNode());
            } else {
              // Will this ever happen?
              const textContent = selection.getTextContent();
              const codeNode = $createCodeNode();
              selection.insertNodes([codeNode]);
              selection.insertRawText(textContent);
            }
          }
        }),
    },
  ),
  new ComponentPickerOption(
    { title: 'Media' },
    {
      isGroupTitle: true,
      onSelect: () => {},
    },
  ),
  ...customEmbedConfigs.map(
    embedConfig =>
      new ComponentPickerOption(
        { title: embedConfig.contentName, subTitle: embedConfig.description },
        {
          icon: embedConfig.icon,
          keywords: [...embedConfig.keywords, 'embed'],
          onSelect: () => editor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type),
        },
      ),
  ),
];

export default getBaseOptions;
