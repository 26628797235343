import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import InitialChatScreen from '@/views/Knowledge/components/InitialChatScreen';
import { MessagesValue } from '@/views/Knowledge/OpenKnowledge';
import { Box, CircularProgress, IconButton, Stack, useTheme } from '@mui/material';
import Icon from '@/components/Icon/Icon';
import { useDropzone } from 'react-dropzone';
import { acceptedFileTypes } from '@/utils/consts';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useKnowledge from '@/views/Knowledge/useKnowledge';
import {
  DocumentMetadata,
  getGetAllThreadsQueryKey,
  getGetThreadByIdQueryKey,
  ModelsThreadMessage,
  Source,
  Thread,
  useChatWithKnowledgeBaseNewQuestion,
  useGetFollowUpQuestions,
  useUploadDocToKnowledgeBaseHook,
  useGetMentionsByType,
  MentionType,
} from '@/api/generated';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { convertMarkdownToHtmlSafely } from '@/utils/convertMarkdownToHtmlSafely';
import { TABS } from '@/views/Knowledge/components/consts';
import { MIN_WIDTH } from '@/views/Knowledge/utils/recalculateSafePositionForCollapsed';
import Message from '@/views/Knowledge/components/Message';
import nextTick from '@/services/nextTick';
import SendIconButton from '@/components/SendIconButton';
import FormMentionsInputSubmit from '@/components/FormMentionsInputSubmit/FormMentionsInputSubmit';
import { useOrganization } from '@/hooks/useOrganization';
import { useQueryClient } from '@tanstack/react-query';
import { ejectMentionsFromText } from '@/utils/ejectMentionsFromText';
import { enqueueSnackbar } from 'notistack';
import { getMentionsByType } from '@/utils/getMentionsByType';

interface ChatProps {
  messages: MessagesValue[];
  tabValue: TABS;
  setMessages: (messages: MessagesValue[]) => void;
  isWeb: boolean;
  currentQuestionResponse?: Thread;
  setCurrentQuestionResponse: (currentQuestionResponse?: Thread) => void;
  setTabValue: (tabValue: TABS) => void;
  uploadingFilesPromisesRef: MutableRefObject<Promise<DocumentMetadata>[]>;
}

export interface FormCreateQuestion {
  questionsCreation: string;
}

const validationSchemaCreateQuery = z.object({ questionsCreation: z.string() }).required();

const getQuestionsAndAnswers = (messages?: ModelsThreadMessage[]) => {
  if (!messages) return { questions: undefined, answers: undefined };
  const questions = messages.filter((_, i) => i % 2 === 0).map(({ content }) => content);
  const answers = messages?.filter((_, i) => i % 2 !== 0)?.map(({ content }) => convertMarkdownToHtmlSafely(content));
  return { questions, answers };
};

const Chat: FC<ChatProps> = ({
  tabValue,
  messages,
  setMessages,
  isWeb,
  currentQuestionResponse,
  setCurrentQuestionResponse,
  setTabValue,
  uploadingFilesPromisesRef,
}) => {
  const { palette, spacing } = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const [inputContainerHeight, setInputContainerHeight] = useState(0);
  const { currentThread, currentThreadId, isLoadingUpload, isActive, setLoadingUpload, uploadedFilesRefetch } = useKnowledge();
  const uploadFile = useUploadDocToKnowledgeBaseHook();
  const queryClient = useQueryClient();
  const { organization } = useOrganization();
  const { t } = useTranslation('common');
  const [isDisabledField, setIsDisabledField] = useState(false);
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting: isSubmittingCreateQuery, isDirty },
    reset,
    setValue,
  } = useForm<FormCreateQuestion>({
    resolver: zodResolver(validationSchemaCreateQuery),
    defaultValues: { questionsCreation: '' },
  });

  const questionsCreation = watch('questionsCreation');
  const formInputSubmitRef = useRef<HTMLTextAreaElement | null>(null);

  const { mutateAsync: createQuestionMutation, isPending: isQuestionPending } = useChatWithKnowledgeBaseNewQuestion();
  const { mutate: getSuggestions, data: suggestions, isPending: isSuggestionsLoading } = useGetFollowUpQuestions();
  const [pendingQuestion, setPendingQuestion] = useState<string | null>(null);

  const { data: mentionsByType = [] } = useGetMentionsByType();

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (!container) return;

    nextTick(() => container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' }));
  };

  const scrollToBottomWhenOnBottom = () => {
    const container = containerRef.current;
    if (!container) return;

    const isScrollAtBottom = container.scrollTop === container.scrollHeight - container.clientHeight;
    if (!isScrollAtBottom) return;

    scrollToBottom();
  };

  useEffect(() => {
    nextTick(() => setInputContainerHeight(inputContainerRef.current?.offsetHeight ?? 0));
    scrollToBottom();
  }, [questionsCreation, inputContainerRef]);

  useEffect(() => {
    if (isActive && formInputSubmitRef.current) {
      formInputSubmitRef.current.focus();
    }
  }, [tabValue, isActive]);

  useEffect(() => {
    if (!isActive) return;

    getSuggestions({
      data: {
        source: Source.org_knowledge,
        context: { page_id: '', query_id: '', equipment_id: '', text: '' },
      },
    });
  }, [isActive]);

  useEffect(() => {
    const { questions, answers } = getQuestionsAndAnswers(currentThread?.messages);
    const questionsAndAnswers = questions?.map((question, i) => ({ question, answer: answers?.[i] }));
    setMessages(questionsAndAnswers ?? []);
  }, [currentThread]);

  useEffect(() => {
    setIsDisabledField(!!questionsCreation);
    !questionsCreation && setValue('questionsCreation', '');
  }, [questionsCreation]);

  useEffect(() => {
    const { questions, answers } = getQuestionsAndAnswers(currentThread?.messages || currentQuestionResponse?.messages);
    const questionsAndAnswers = questions?.map((question, i) => ({ question, answer: answers?.[i] }));
    setMessages(questionsAndAnswers ?? []);
    reset();
  }, [tabValue]);

  useEffect(() => {
    isQuestionPending && setMessages(messages);
  }, [tabValue, isQuestionPending]);

  const handleUpload = (files: File[]) => {
    setLoadingUpload(true);
    return files.map(async (file: File) => {
      const uploadPromise = uploadFile(organization.id, { file });
      uploadingFilesPromisesRef.current.push(uploadPromise);
      try {
        const document = await uploadPromise;
        setLoadingUpload(false);
        uploadedFilesRefetch();
        enqueueSnackbar(t('knowledge.uploadSucceed'));
        return document;
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t('knowledge.uploadFailed'), { variant: 'error' });
      } finally {
        uploadingFilesPromisesRef.current = uploadingFilesPromisesRef.current.filter(promise => promise !== uploadPromise);
        setLoadingUpload(false);
      }
    });
  };

  const onSuggestionSelect = (text: string) => {
    setValue('questionsCreation', text);
    formInputSubmitRef.current?.focus();
  };

  const addQuestion = (newMessage: MessagesValue) => {
    setMessages([...messages, newMessage]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: acceptedFileTypes,
  });

  const onCreateQuestion = async () => {
    scrollToBottomWhenOnBottom();
    const { text: pendingQuestionText } = ejectMentionsFromText(questionsCreation);
    setPendingQuestion(pendingQuestionText);

    try {
      const threadId = currentThreadId || currentQuestionResponse?.thread_id;
      const { text: questionText, mentions } = ejectMentionsFromText(questionsCreation);
      const thread = await createQuestionMutation(
        {
          orgId: organization.id,
          data: { question_receive: { mentions, question: questionText }, thread_id: threadId, use_web: isWeb },
        },
        {
          onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: [getGetAllThreadsQueryKey(organization.id)] });
            queryClient.invalidateQueries({ queryKey: [getGetThreadByIdQueryKey(organization.id, threadId!)] });
          },
        },
      );
      setCurrentQuestionResponse(thread);
      const { questions, answers } = getQuestionsAndAnswers(thread.messages);
      const questionsAndAnswers = questions?.map((question, i) => ({ question, answer: answers?.[i] }));
      questionsAndAnswers?.map(message => addQuestion(message));
      reset({ questionsCreation: '' });
    } catch (err) {
      console.error(err);
    }

    setPendingQuestion(null);
    scrollToBottomWhenOnBottom();
  };

  const { onClick, ...rootProps } = getRootProps();

  const endAdornment = (
    <Stack
      sx={{
        flexDirection: 'row',
        minHeight: 'auto',
        height: 'auto',
        position: 'absolute',
        right: 0,
        bottom: 0,
        mb: 0.25,
      }}
    >
      <Box sx={{ mr: 0.5, display: 'flex', alignItems: 'center' }}>
        {isLoadingUpload ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton onClick={onClick}>
            <Icon name="attachment" fontSize="small" />
          </IconButton>
        )}
        <input {...getInputProps()} />
      </Box>
      <SendIconButton disabled={!isDirty && !isDisabledField} loading={isSubmittingCreateQuery || isQuestionPending} />
    </Stack>
  );

  return (
    <Box sx={{ maxHeight: '100%', mt: 'auto', px: 6, pt: 2, overflowY: 'auto' }} ref={containerRef}>
      <Box {...rootProps} sx={{ pb: `${inputContainerHeight + parseInt(spacing(5))}px` }}>
        {!isQuestionPending && !isSubmittingCreateQuery && !messages.length ? (
          <InitialChatScreen
            suggestions={suggestions}
            isSuggestionsLoading={isSuggestionsLoading}
            setTabValue={setTabValue}
            onSuggestionSelect={onSuggestionSelect}
          />
        ) : (
          <Stack sx={{ height: '100%' }}>
            {messages.map(message => (
              <Message message={message} />
            ))}
            {pendingQuestion && (
              <Message message={{ question: pendingQuestion, answer: `<p>${t('knowledge.pendingMessage')}</p>` }} />
            )}
          </Stack>
        )}
        <Box
          ref={inputContainerRef}
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '80%',
            minWidth: MIN_WIDTH * 0.8,
            left: '50%',
            transform: 'translateX(-50%)',
            mb: 3,
            zIndex: 1,
          }}
        >
          <FormMentionsInputSubmit
            ref={formInputSubmitRef}
            handleSubmit={handleSubmit(onCreateQuestion)}
            disabled={isSubmittingCreateQuery}
            error={errors.questionsCreation}
            documents={getMentionsByType(mentionsByType, MentionType.org_document)}
            tools={getMentionsByType(mentionsByType, MentionType.tool)}
            control={control}
            fieldName="questionsCreation"
            placeholder={t('knowledge.placeholders.askPellesPlaceholder')}
            startIcon={<Icon name="ai" fontSize="xsmall" sx={{ ml: 2, color: palette.primary.dark }} />}
            endIcon={endAdornment}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
