import { FC } from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';

export interface AiMenuItemProps extends MenuItemProps {}

const AiMenuItem: FC<AiMenuItemProps> = ({ sx, ...props }) => (
  <MenuItem {...props} sx={{ display: 'flex', gap: 1, fontSize: 'body3.fontSize', ...sx }} />
);

export default AiMenuItem;
