import { useEffect } from 'react';

const useEditorClick = (handleClick: () => void) => {
  useEffect(() => {
    document.addEventListener('lexicalEditorClick', handleClick);

    return () => {
      document.removeEventListener('lexicalEditorClick', handleClick);
    };
  }, [handleClick]);
};

export default useEditorClick;
