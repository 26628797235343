import { Box, ButtonBase, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns/format';
import { formatDistance } from 'date-fns';
import { FC } from 'react';
import { ThreadMetadata } from '@/api/generated';
import DocumentDelete from '@/components/DocumentDelete/DocumentDelete';
import { useTranslation } from 'react-i18next';

interface ThreadProps {
  thread: ThreadMetadata;
  handleThread: (id: string) => void;
  onDelete: (threadId: string) => Promise<unknown>;
  isPendingDelete: boolean;
}

const Thread: FC<ThreadProps> = ({ thread, handleThread, onDelete }) => {
  const { palette, spacing } = useTheme();
  const { title, description, last_modified, thread_id } = thread;
  const { t } = useTranslation('knowledgeBase');

  return (
    <ListItem
      divider
      sx={{
        p: spacing(0, 5, 0, 0),
        '&:hover': {
          backgroundColor: palette.primary.main,
          '& .MuiIconButton-root': { display: 'flex' },
          '& .delete-button': { opacity: 1 },
        },
        '&.MuiListItem-divider': { borderColor: palette.grey[500], '&:last-of-type': { borderBottom: 'none' } },
        '& .delete-button': { opacity: 0 },
      }}
    >
      <Stack flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
        <ButtonBase
          sx={{
            textAlign: 'left',
            color: palette.text.primary,
            p: spacing(1.5, 2.25, 1.5, 6),
            width: '100%',
            justifyContent: 'flex-start',
            borderRadius: 'unset',
            textTransform: 'unset',
          }}
          onClick={() => handleThread(thread_id)}
        >
          <Stack gap={1.25}>
            <Typography variant="h6" sx={{ fontSize: 'body2.fontSize', lineHeight: 'normal', color: palette.grey[800] }}>
              {title}
            </Typography>
            {description && <Typography sx={{ color: palette.grey[700], fontSize: 'body3.fontSize' }}>{description}</Typography>}
            <Box
              component="time"
              sx={{
                color: palette.grey[700],
                fontSize: 'body3.fontSize',
                fontWeight: 'fontWeightRegular',
                lineHeight: 'normal',
              }}
              dateTime={format(new Date(last_modified ?? ''), 'P')}
            >
              {formatDistance(new Date(last_modified ?? ''), new Date(), { addSuffix: true })}
            </Box>
          </Stack>
        </ButtonBase>
        <DocumentDelete
          sx={{ fontSize: 'body1.fontSize' }}
          title={t('confirmationModal.description')}
          onDelete={() => onDelete(thread_id)}
        />
      </Stack>
    </ListItem>
  );
};

export default Thread;
