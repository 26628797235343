import { FC, ReactElement, useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import FabButton, { FabButtonProps } from '@/components/FabMenu/FabButton';
import { Box, ClickAwayListener, Popper, Stack, SvgIcon, SxProps, Tooltip, useTheme } from '@mui/material';
import { bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

type ChildrenElement = ReactElement<FabButtonProps> | boolean;

interface FabProps {
  popupId: string;
  sx?: SxProps;
  children: ChildrenElement | Array<ChildrenElement>;
  resetSlider: () => void;
}

const FabMenu: FC<FabProps> = ({ sx, popupId, children, resetSlider }) => {
  const { spacing } = useTheme();
  const popupState = usePopupState({ variant: 'popper', popupId });
  const { t } = useTranslation('project');
  const onClickAway = () => {
    popupState.close();
  };
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    if (!popupState.isOpen) return;
    setOpenTooltip(false);
    resetSlider();
  }, [popupState.isOpen]);

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Tooltip
          arrow
          title={t('pages.tooltips.fabMenu')}
          disableInteractive
          open={openTooltip}
          onOpen={() => setOpenTooltip(true)}
          onClose={() => setOpenTooltip(false)}
        >
          <FabButton {...bindToggle(popupState)}>
            <SvgIcon component={BsThreeDots} inheritViewBox />
          </FabButton>
        </Tooltip>
      </ClickAwayListener>
      <Popper
        {...bindPopper(popupState)}
        placement="top"
        modifiers={[{ name: 'offset', options: { offset: [0, parseInt(spacing(2))] } }]}
      >
        <Stack gap={2}>{children}</Stack>
      </Popper>
    </Box>
  );
};

export default FabMenu;
