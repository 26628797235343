import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DriveFileType } from '@/types';
import { ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';
import { useCloudFilesDialog } from '@/hooks/useCloudFilesDialog';
import { CloudFilesDialogContextType } from '@/contexts/CloudFilesDialogContext';
import { HomepageActions } from '@/views/Project/views/Header/types';
import { DefaultParams } from '@/views/Projects/components/ProjectFormDialog/components/CloudFilesDialog';

type Params = {
  documentId?: string;
  importDriveFiles: ImportDriveFilesFn;
};

export const useDrivesImport = ({ documentId, importDriveFiles }: Params) => {
  const { showCloudFilesDialog } = useCloudFilesDialog();
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorContext, setErrorContext] = useState<CloudFilesDialogContextType | null>(null);

  const redirectUrl = useMemo(() => {
    if (!errorContext) return '';

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('action', HomepageActions.OPEN_EDIT_PROJECT_DIALOG);
    urlParams.set('cloudParams', JSON.stringify(errorContext));
    const url = new URL(window.location.href);
    url.search = urlParams.toString();
    return url.toString();
  }, [errorContext]);

  const openDriveDialog = useCallback(
    async (driveType: DriveFileType, defaultParams?: DefaultParams) => {
      setErrorContext(null);
      const result = await showCloudFilesDialog(driveType, { defaultParams, onError: setErrorContext });
      if (result) {
        importDriveFiles(result, { type: driveType, versionedFileId: documentId });
      }
    },
    [importDriveFiles, documentId],
  );

  useEffect(() => {
    const cloudParamsString = searchParams.get('cloudParams');
    if (!cloudParamsString) return;

    setSearchParams(prevParams => {
      const nextParams = new URLSearchParams(prevParams);
      nextParams.delete('cloudParams');
      return nextParams;
    });

    const { type, ...defaultParams } = JSON.parse(cloudParamsString) ?? {};
    openDriveDialog(type, defaultParams);
  }, [searchParams, openDriveDialog]);

  const onOneDriveClick = (event: MouseEvent) => {
    event.stopPropagation();
    openDriveDialog('onedrive');
  };

  const onGoogleDriveClick = async (event: MouseEvent) => {
    event.stopPropagation();
    openDriveDialog('google');
  };

  const onAutodeskDriveClick = async (event: MouseEvent) => {
    event.stopPropagation();
    openDriveDialog('autodesk');
  };

  return {
    redirectUrl,
    errorContext,
    onOneDriveClick,
    onGoogleDriveClick,
    onAutodeskDriveClick,
  };
};
