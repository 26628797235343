import { forwardRef, ReactElement, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { useTheme, Box } from '@mui/material';
import { $getSelection, COMMAND_PRIORITY_LOW, LexicalEditor, SELECTION_CHANGE_COMMAND } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { EDITOR_SHEET_LEFT_PADDING_SPACING } from '@/containers/PagesEditor/constants';
import { setFloatingElemPositionUnderTarget } from '@/containers/PagesEditor/utils/setFloatingElemPositionUnderTarget';
import getDOMRangeRect from '@/containers/PagesEditor/utils/getDOMRangeRect';
import nextTick from '@/services/nextTick';

interface AiPopupProps {
  className?: string;
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  width: string | number;
  children: ReactElement;
  onClose: () => void;
}

const AiPopup = forwardRef<HTMLDivElement | null, AiPopupProps>(
  ({ className, editor, anchorElem, width, children, onClose }, outerRef) => {
    const { spacing } = useTheme();
    const innerRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(outerRef, () => innerRef.current as HTMLDivElement, []);

    const $updatePopup = useCallback(() => {
      const selection = $getSelection();

      const popupElem = innerRef.current;
      const nativeSelection = window.getSelection();

      if (!popupElem) return;

      const rootElement = editor.getRootElement();
      if (
        selection !== null &&
        nativeSelection !== null &&
        rootElement !== null &&
        rootElement.contains(nativeSelection.anchorNode)
      ) {
        const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

        setFloatingElemPositionUnderTarget(rangeRect, popupElem, anchorElem, {
          verticalGap: 10,
          horizontalOffset: parseInt(spacing(EDITOR_SHEET_LEFT_PADDING_SPACING)),
          addElementHeight: true,
        });
      }
    }, [editor, anchorElem]);

    useEffect(() => {
      editor.getEditorState().read(() => {
        $updatePopup();
      });
      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            $updatePopup();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            nextTick(() => $updatePopup());
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      );
    }, [editor, $updatePopup]);

    return (
      <Box
        className={className}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10,
        }}
        onClick={onClose}
      >
        <Box
          ref={innerRef}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width,
          }}
          onClick={event => event.stopPropagation()}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

export default AiPopup;
