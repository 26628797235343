import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { HeadingNode, HeadingTagType } from '@lexical/rich-text';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';
import { Style } from '@react-pdf/types';

interface DividerViewProps {
  node: HeadingNode;
}

const stylesByTag: Partial<Record<HeadingTagType, Style>> = {
  h1: { fontSize: 26, fontWeight: 600 },
  h2: { fontSize: 20, fontWeight: 600 },
  h3: { fontSize: 16, fontWeight: 600 },
};

const HeadingView: FC<DividerViewProps> = ({ node }) => (
  <View>
    <Text style={stylesByTag[node.getTag()]}>
      <LexicalNodePdfView nodes={node.getChildren()} />
    </Text>
  </View>
);

export default HeadingView;
