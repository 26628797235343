import { FC, ReactNode, useRef, useState } from 'react';
import CloudFilesDialog, { DefaultParams } from '@/views/Projects/components/ProjectFormDialog/components/CloudFilesDialog';
import { DriveFile, DriveFileType, DriveNode } from '@/types';
import { useGooglePicker } from '@/views/Projects/components/ProjectFormDialog/hooks/useGooglePicker';
import {
  CloudFilesDialogContext,
  CloudFilesDialogContextValue,
  CloudFilesDialogErrorCallback,
  CloudFilesDialogParams,
} from '@/contexts/CloudFilesDialogContext';

interface CloudFilesDialogProviderProps {
  children: ReactNode;
}

export const CloudFilesDialogProvider: FC<CloudFilesDialogProviderProps> = ({ children }) => {
  const [driveType, setDriveType] = useState<DriveFileType | 'none'>('none');
  const resolveRef = useRef<(result: DriveFile[] | false) => void>(() => false);
  const defaultParamsRef = useRef<DefaultParams | undefined>(undefined);
  const errorCallbackRef = useRef<CloudFilesDialogErrorCallback>(() => false);

  const { pickerRef, openPicker } = useGooglePicker({
    onAction: (response: google.picker.ResponseObject) => {
      const files = response[google.picker.Response.DOCUMENTS];
      if (response[google.picker.Response.ACTION] !== google.picker.Action.PICKED || !files) return;

      pickerRef.current?.dispose();
      resolveRef.current(files);
    },
  });

  const contextValue: CloudFilesDialogContextValue = {
    showCloudFilesDialog: async (nextDriveType: DriveFileType, { defaultParams, onError }: CloudFilesDialogParams = {}) => {
      errorCallbackRef.current = onError ?? (() => false);
      defaultParamsRef.current = defaultParams;

      try {
        nextDriveType == 'google' ? await openPicker() : setDriveType(nextDriveType);
      } catch (error) {
        onError?.({ type: nextDriveType, error });
      }

      return new Promise<DriveFile[] | false>(resolve => {
        resolveRef.current = resolve;
      });
    },
  };

  const onDialogClose = (result: DriveFile[] | false) => {
    resolveRef.current(result);
    setDriveType('none');
    defaultParamsRef.current = undefined;
    errorCallbackRef.current = () => false;
  };

  const onNodeSelect = (node: DriveNode) => {
    onDialogClose([node.native]);
  };

  return (
    <CloudFilesDialogContext.Provider value={contextValue}>
      <CloudFilesDialog
        defaultParams={defaultParamsRef.current}
        driveFilesType={driveType}
        onNodeSelect={onNodeSelect}
        onClose={() => onDialogClose(false)}
        onError={errorCallbackRef.current}
      />
      {children}
    </CloudFilesDialogContext.Provider>
  );
};
