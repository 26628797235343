import { Components, Theme } from '@mui/material/styles';

const MuiDataGrid: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(2),
      boxShadow: theme.shadows[3],
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader': {
        padding: 0,
        cursor: 'default',
        '& .MuiDataGrid-iconButtonContainer': {
          display: 'none',
        },
        '&:focus, &:focus-within ': {
          outline: 'unset',
        },
      },
      '& .MuiDataGrid-row': {
        '&.MuiDataGrid-row--editing': { boxShadow: theme.shadows[0] },
        '&.empty-row': {
          backgroundColor: theme.palette.grey[100],
          '& .MuiDataGrid-cell': {
            color: theme.palette.grey[400],
          },
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
            '& .row-actions': {
              display: 'none',
            },
          },
        },
        '& .MuiDataGrid-cell': {
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          '&:focus, &:focus-within ': {
            outline: 'unset',
          },
          '& .MuiDataGrid-editInputCell': {
            input: {
              padding: theme.spacing(0, 3),
            },
          },
          '&.MuiDataGrid-cell--editing': {
            boxShadow: theme.shadows[0],
            backgroundColor: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey[200]}`,
            input: {
              paddingLeft: theme.spacing(3),
              paddingRight: 0,
            },
          },
          '&[data-field="name"]': {
            fontWeight: 'fontWeightMedium',
          },
          '&[data-field="actions"]': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        '&:hover': {
          '& .row-actions': {
            display: 'flex',
          },
        },
      },
    }),
  },
};

export default MuiDataGrid;
