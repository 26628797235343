import { createContext } from 'react';
import { DriveFile, DriveFileType } from '@/types';
import { DefaultParams } from '@/views/Projects/components/ProjectFormDialog/components/CloudFilesDialog';

export type CloudFilesDialogContextType = {
  error: unknown;
  type: DriveFileType;
  folderId?: string;
  hubId?: string;
  projectId?: string;
};

export type CloudFilesDialogErrorCallback = (context: CloudFilesDialogContextType) => void;

export type CloudFilesDialogParams = {
  defaultParams?: DefaultParams;
  onError?: CloudFilesDialogErrorCallback;
};

export type CloudFilesDialogContextValue = {
  showCloudFilesDialog: (driveType: DriveFileType, params?: CloudFilesDialogParams) => Promise<DriveFile[] | false>;
};

export const CloudFilesDialogContext = createContext<CloudFilesDialogContextValue | null>(null);
