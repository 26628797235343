import { $getSelection, $isRangeSelection, ElementNode, LexicalEditor, TextNode } from 'lexical';
import { positionNodeOnRange } from '@lexical/utils';
import invariant from 'shared/invariant';
import { toggleHighlightStyles } from '@/containers/PagesEditor/hooks/utils';

type Options = {
  selectionColor: string;
};

// Part of this code was copied from lexical repo markSelection from @lexical/utils package.
// It was moved because it doesn't have a way to change styles from outside.
export class FakeSelection {
  previousAnchorNode: null | TextNode | ElementNode = null;
  previousAnchorOffset: null | number = null;
  previousFocusNode: null | TextNode | ElementNode = null;
  previousFocusOffset: null | number = null;
  range: Range | null = null;
  removeRangeListener: () => void = () => {};

  constructor(
    private editor: LexicalEditor,
    private options: Options,
  ) {}

  reset() {
    this.range = null;
    this.previousAnchorNode = null;
    this.previousAnchorOffset = null;
    this.previousFocusNode = null;
    this.previousFocusOffset = null;
    this.removeRangeListener();
    this.removeRangeListener = () => {};
  }

  calculatePositions() {
    this.editor.getEditorState().read(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      const { anchor, focus } = selection;
      const currentAnchorNode = anchor.getNode();
      const currentAnchorNodeKey = currentAnchorNode.getKey();
      const currentAnchorOffset = anchor.offset;
      const currentFocusNode = focus.getNode();
      const currentFocusNodeKey = currentFocusNode.getKey();
      const currentFocusOffset = focus.offset;
      const currentAnchorNodeDOM = this.editor.getElementByKey(currentAnchorNodeKey);
      const currentFocusNodeDOM = this.editor.getElementByKey(currentFocusNodeKey);

      const differentAnchorDOM =
        this.previousAnchorNode === null ||
        currentAnchorNodeDOM === null ||
        currentAnchorOffset !== this.previousAnchorOffset ||
        currentAnchorNodeKey !== this.previousAnchorNode.getKey() ||
        (currentAnchorNode !== this.previousAnchorNode &&
          (!(this.previousAnchorNode instanceof TextNode) ||
            currentAnchorNode.updateDOM(this.previousAnchorNode, currentAnchorNodeDOM, this.editor._config)));
      const differentFocusDOM =
        this.previousFocusNode === null ||
        currentFocusNodeDOM === null ||
        currentFocusOffset !== this.previousFocusOffset ||
        currentFocusNodeKey !== this.previousFocusNode.getKey() ||
        (currentFocusNode !== this.previousFocusNode &&
          (!(this.previousFocusNode instanceof TextNode) ||
            currentFocusNode.updateDOM(this.previousFocusNode, currentFocusNodeDOM, this.editor._config)));

      this.previousAnchorNode = currentAnchorNode;
      this.previousAnchorOffset = currentAnchorOffset;
      this.previousFocusNode = currentFocusNode;
      this.previousFocusOffset = currentFocusOffset;

      if (!differentAnchorDOM || !differentFocusDOM) return;

      const anchorHTMLElement = this.editor.getElementByKey(anchor.getNode().getKey());
      const focusHTMLElement = this.editor.getElementByKey(focus.getNode().getKey());

      if (!anchorHTMLElement && !focusHTMLElement) return;

      let firstHTMLElement;
      let firstOffset;
      let lastHTMLElement;
      let lastOffset;
      if (focus.isBefore(anchor)) {
        firstHTMLElement = focusHTMLElement;
        firstOffset = focus.offset;
        lastHTMLElement = anchorHTMLElement;
        lastOffset = anchor.offset;
      } else {
        firstHTMLElement = anchorHTMLElement;
        firstOffset = anchor.offset;
        lastHTMLElement = focusHTMLElement;
        lastOffset = focus.offset;
      }

      if (!firstHTMLElement || !lastHTMLElement) return;

      const firstTextNode = firstHTMLElement.firstChild;
      invariant(firstTextNode !== null, 'Expected text node to be first child of span');
      const lastTextNode = lastHTMLElement.firstChild;
      invariant(lastTextNode !== null, 'Expected text node to be first child of span');

      this.range = document.createRange();
      this.range.setStart(firstTextNode, firstOffset);
      this.range.setEnd(lastTextNode, lastOffset);
    });
  }

  add() {
    if (!this.range) return;

    this.removeRangeListener();
    this.removeRangeListener = positionNodeOnRange(this.editor, this.range, domNodes => {
      for (const domNode of domNodes) {
        toggleHighlightStyles(domNode.style, this.options.selectionColor);
      }
    });
  }
}
