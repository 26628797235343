import { KeyboardEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { SxProps } from '@mui/material';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import SelectControl, { ListItem } from '@/components/SelectControl';
import { AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import { getOptionValue } from '@/utils/getOptionValue';

interface GoogleAutoCompleteProps<Values extends FieldValues = FieldValues> {
  id: string;
  label: string;
  labelSx?: SxProps;
  placeholder: string;
  control: Control<Values>;
  name: FieldPath<Values>;
  options?: Omit<google.maps.places.AutocompletionRequest, 'input'>;
  onKeyDown: (event: KeyboardEvent) => void;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  onBlur?: (value?: string) => void;
  isInReadMode?: boolean;
  sx?: SxProps;
  autoFocus?: boolean;
  endAdornment?: ReactNode;
}

export default function GoogleAutoComplete<Values extends FieldValues>({
  id,
  label,
  labelSx,
  control,
  placeholder,
  name,
  options,
  onKeyDown,
  onPlaceSelected,
  onBlur,
  isInReadMode = false,
  sx,
  endAdornment,
  autoFocus,
}: GoogleAutoCompleteProps<Values>) {
  const { field } = useController({ name, control });
  const { onChange: onFieldChange, onBlur: onFieldBlur, value: fieldValue, ...fieldProps } = field;
  const [predictions, setPredictions] = useState<google.maps.places.PlaceResult[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const lastValidValueRef = useRef<string | undefined>(fieldValue);
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE_MAP_KEY,
  });

  useEffect(() => {
    if (fieldValue !== '') {
      lastValidValueRef.current = fieldValue;
    }
  }, [fieldValue]);

  const autocompleteOptions = useMemo(
    () =>
      predictions.map(prediction => ({
        title: prediction.formatted_address ?? 'unknown',
        value: prediction,
      })),
    [predictions],
  );
  useEffect(() => {
    if (!placePredictions.length || !placesService) {
      return;
    }

    (async () => {
      try {
        setIsFetching(true);
        const items = await Promise.all(
          placePredictions.slice(0, 10).map(
            prediction =>
              new Promise<google.maps.places.PlaceResult | null>(resolve =>
                placesService.getDetails(
                  {
                    placeId: prediction.place_id,
                    fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
                  },
                  result => resolve(result),
                ),
              ),
          ),
        );
        setPredictions(items.filter(item => !!item));
      } catch (error) {
        console.error('Error in google places autocomplete', error);
      }
      setIsFetching(false);
    })();
  }, [placePredictions]);

  const onChange = (
    _event: React.SyntheticEvent,
    placeResult: ListItem<AutocompleteValue<google.maps.places.PlaceResult, false, false, false>>,
  ) => {
    if (placeResult) onPlaceSelected(getOptionValue(placeResult) as google.maps.places.PlaceResult);
    onBlur && onBlur();
  };

  const onInputChange = (_event: React.SyntheticEvent, value: string) => {
    if (value !== '') {
      lastValidValueRef.current = value;
    }
    getPlacePredictions({ ...options, input: value });
    onFieldChange(value);
  };

  const handleBlur = () => {
    if (fieldValue === '') {
      onFieldChange(lastValidValueRef.current ?? '');
    }
    onFieldBlur();
    setPredictions([]);
    onBlur && onBlur();
  };

  return (
    <SelectControl
      id={id}
      label={label}
      labelSx={labelSx}
      freeSolo
      placeholder={placeholder}
      loading={isPlacePredictionsLoading || isFetching}
      filterOptions={x => x}
      // @ts-expect-error
      options={autocompleteOptions}
      control={control}
      disableClearable
      inputValue={fieldValue ?? ''}
      onBlur={handleBlur}
      // @ts-expect-error
      onChange={onChange}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      readOnly={isInReadMode}
      {...fieldProps}
      endAdornment={endAdornment}
      autoFocus={autoFocus}
      sx={sx}
    />
  );
}
