import { QueryClient } from '@tanstack/react-query';
import { getGetAllPagesQueryKey, PageMetadata } from '@/api/generated';

type Params = {
  queryClient: QueryClient;
  projectSlug?: string;
};

export const updatePagesCache = (
  { queryClient, projectSlug }: Params,
  cb: (prevPages?: PageMetadata[]) => PageMetadata[] | undefined,
) => {
  if (!projectSlug) return;
  queryClient.setQueryData<PageMetadata[]>(getGetAllPagesQueryKey(projectSlug), cb);
};
