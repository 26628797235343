import { AnnotationBackendJSON, AnnotationsUnion, Instance } from 'pspdfkit';
import { toolbarItems } from '@/containers/DocumentEditor/utils/toolbarItems';
import { EquipmentDescriptionSearch, UnitType } from '@/api/generated';

export enum CountingMode {
  MULTI_COUNTING = 'multi-count',
  AUTO_COUNTING = 'auto-count',
  REGULAR = 'regular',
}

export type FavoriteActionType = 'add' | 'remove';

export type MakeToolbarAction = (
  key: keyof typeof toolbarItems,
  params?: { presetData?: Partial<AnnotationsUnion>; category?: EquipmentDescriptionSearch | null; color?: string },
) => void;

type UpdatePspdfCallback = (annotation: AnnotationsUnion) => AnnotationsUnion;

export type EditorMode = 'annotations' | 'compare' | 'hidden';

export type CompareSource = {
  documentId: string;
  page?: number;
  annotations?: AnnotationBackendJSON[];
};
export type ComparisonOptions = { autoCompare?: boolean; splitScreen?: boolean };
export type CompareSourcesConfig = {
  firstSource?: CompareSource;
  secondSource?: CompareSource;
} & ComparisonOptions;

type PspdfAnnotationsDArgs =
  | {
      ids: string[];
      callback?: UpdatePspdfCallback;
      annotations?: undefined;
    }
  | {
      ids?: undefined;
      callback?: undefined;
      annotations: AnnotationsUnion[];
    };

type PspdfAnnotationsCArgs =
  | {
      ids: string[];
      callback: UpdatePspdfCallback;
      annotations?: undefined;
    }
  | {
      ids?: undefined;
      callback?: undefined;
      annotations: AnnotationBackendJSON[] | AnnotationsUnion[];
    };

type PspdfAnnotationsUArgs =
  | {
      ids: string[];
      callback: UpdatePspdfCallback;
      annotations?: undefined;
    }
  | {
      ids?: undefined;
      callback?: undefined;
      annotations: AnnotationsUnion[];
    };

type UpdatePspdfParams =
  | ({
      eventType: 'delete';
    } & PspdfAnnotationsDArgs)
  | ({
      eventType: 'create';
    } & PspdfAnnotationsCArgs)
  | ({
      eventType: 'update';
    } & PspdfAnnotationsUArgs);

export type UpdatePspdfAnnotations = (params: UpdatePspdfParams) => void;

export type JumpToParams = {
  instance?: Instance;
  pageIndex: number;
  bbox: [left: number, top: number, width: number, height: number];
  zoomIn?: boolean;
};

export type SetToolCategoryOptions = { toolType: UnitType };
export type PresetDataParam = { presetData?: Partial<AnnotationsUnion> };
export type MeasurementParams = PresetDataParam & { category?: EquipmentDescriptionSearch | null };
