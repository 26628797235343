import { createPortal } from 'react-dom';
import { FC, ReactNode, useEffect, useRef } from 'react';
import nextTick from '@/services/nextTick';

interface HeaderActionPortalProps {
  children: ReactNode;
}

export const HeaderActionsPortal: FC<HeaderActionPortalProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkAndSetElement = () => {
      nextTick(() => {
        containerRef.current = document.getElementById('header-actions-slot') as HTMLDivElement;
        if (!containerRef.current) checkAndSetElement();
      });
    };
    checkAndSetElement();
  }, []);

  if (!containerRef.current) return null;

  return createPortal(children, containerRef.current);
};
