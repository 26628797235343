import { FC } from 'react';
import { Box } from '@mui/material';

interface ColorCircleProps {
  color: string;
}

const ColorCircle: FC<ColorCircleProps> = ({ color = '#000' }) => (
  <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: color }} />
);

export default ColorCircle;
