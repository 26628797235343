import { Box, Stack, SvgIcon, useTheme } from '@mui/material';
import { DropzoneState } from 'react-dropzone';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UploadIcon from '@/assets/upload-icon.svg?react';
import DropZoneArea from '@/components/DropZoneArea';
import OneDriveIcon from '@/assets/onedrive.svg?react';
import GoogleDriveIcon from '@/assets/google-drive.svg?react';
import ProcoreIcon from '@/assets/procore.svg?react';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import DriveButton from '@/views/Projects/components/ProjectFormDialog/components/DriveButton';
import { ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';
import { useDrivesImport } from '@/views/Projects/components/ProjectFormDialog/hooks/useDrivesImport';
import { isSocialLoginError } from '@/views/Projects/components/ProjectFormDialog/utils/isSocialLoginError';
import { Descope } from '@descope/react-sdk';
import { useOrganization } from '@/hooks/useOrganization';
import snakeCase from 'lodash/snakeCase';

interface DocumentUploadProps extends Pick<DropzoneState, 'getInputProps' | 'isDragActive' | 'getRootProps'> {
  importDriveFiles: ImportDriveFilesFn;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ getInputProps, isDragActive, getRootProps, importDriveFiles }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const { organization } = useOrganization();
  const emailInDropzone = `${snakeCase(organization.name)}@pelles.ai`;

  const { redirectUrl, errorContext, onAutodeskDriveClick, onOneDriveClick, onGoogleDriveClick } = useDrivesImport({
    importDriveFiles,
  });

  const { onClick, ...rootProps } = getRootProps();

  return (
    <>
      {errorContext && errorContext.type === 'google' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-google" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'onedrive' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-microsoft" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'autodesk' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-autodesk" redirectUrl={redirectUrl} />
      )}

      <DropZoneArea sx={{ minHeight: 0, mb: 1.5, p: 1 }} isDragActive={isDragActive} {...rootProps}>
        <input {...getInputProps()} />
        <Stack gap={1.5} alignItems="center" p={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SvgIcon inheritViewBox component={UploadIcon} sx={{ fontSize: 37 }} />
            <Box>
              <Trans
                t={t}
                i18nKey="uploadFiles.generalDropzone"
                values={{ email: emailInDropzone }}
                components={{
                  span: (
                    <Box
                      component="span"
                      sx={{ color: palette.primary.dark, fontWeight: 'fontWeightMedium', textDecoration: 'underline' }}
                      onClick={onClick}
                    />
                  ),
                  a: (
                    <Box
                      component="a"
                      sx={{ color: palette.primary.dark, fontWeight: 'fontWeightMedium' }}
                      href={`mailto:${emailInDropzone}?subject=FilesUpload`}
                      target="_blank"
                      onClick={event => event.stopPropagation()}
                    />
                  ),
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', height: 110 }}>
            <DriveButton
              sx={{ width: 150 }}
              disabled
              icon={<GoogleDriveIcon style={{ width: 40 }} />}
              text={t('uploadFiles.clouds.google')}
              onClick={onGoogleDriveClick}
            />
            <DriveButton
              sx={{ width: 150 }}
              icon={<OneDriveIcon style={{ width: 50 }} />}
              text={t('uploadFiles.clouds.onedrive')}
              onClick={onOneDriveClick}
            />
            <DriveButton
              sx={{ width: 150 }}
              icon={<AutodeskIcon style={{ width: 50 }} />}
              text={t('uploadFiles.clouds.autodesk')}
              onClick={onAutodeskDriveClick}
            />
            <DriveButton
              sx={{ width: 150 }}
              disabled
              icon={<ProcoreIcon style={{ width: 40, height: 40 }} />}
              text={t('uploadFiles.clouds.procore')}
            />
          </Box>
        </Stack>
      </DropZoneArea>
    </>
  );
};

export default DocumentUpload;
