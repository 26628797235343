import { FC } from 'react';
import { ButtonBase, ButtonBaseProps, useTheme } from '@mui/material';

export interface ToolbarMenuItemProps extends ButtonBaseProps {
  isSelected?: boolean;
}

const ToolbarMenuItem: FC<ToolbarMenuItemProps> = ({ isSelected, sx, ...props }) => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      {...props}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        p: 1,
        borderRadius: 1,
        justifyContent: 'flex-start',
        backgroundColor: isSelected ? palette.grey[300] : 'transparent',
        '&:hover': { backgroundColor: isSelected ? palette.grey[300] : palette.grey[100] },
      }}
    />
  );
};

export default ToolbarMenuItem;
