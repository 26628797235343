const px = (value: number) => `${value}px`;

export const toggleHighlightStyles = (style: CSSStyleDeclaration, backgroundColor: string) => {
  style.boxSizing = 'content-box';

  if (style.borderRadius !== px(1)) {
    style.borderRadius = px(1);
  }
  if (style.background !== backgroundColor) {
    style.background = backgroundColor;
  }
  if (style.pointerEvents !== 'none') {
    style.pointerEvents = 'none';
  }
  if (style.marginTop !== px(-3.5)) {
    style.marginTop = px(-3.5);
  }
  if (style.paddingTop !== px(6)) {
    style.paddingTop = px(6);
  }
  if (style.paddingBottom !== px(2)) {
    style.paddingBottom = px(2);
  }
};
