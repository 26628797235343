import { FC } from 'react';
import { ListType } from '@lexical/list';
import { Text, View } from '@react-pdf/renderer';

interface ListItemMarkProps {
  index: number;
  type: ListType;
  checked?: boolean;
}

const ListItemMark: FC<ListItemMarkProps> = ({ index, type, checked }) => {
  if (type === 'bullet') return <Text style={{ flexShrink: 0 }}> • </Text>;
  if (type === 'number') return <Text style={{ flexShrink: 0 }}>{index + 1}.</Text>;

  return (
    <View
      style={{
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        border: 1,
        borderRadius: 4,
        width: 14,
        height: 14,
      }}
    >
      {checked && <Text style={{ fontSize: 10 }}>V</Text>}
    </View>
  );
};

export default ListItemMark;
