import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { FC } from 'react';

interface LinearProgressWithLabelProps extends Omit<LinearProgressProps, 'value'> {
  progress: number;
  height?: number;
}

const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = ({ sx, height, progress, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx,
    }}
  >
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress {...props} variant="determinate" value={progress} sx={{ height }} />
    </Box>
    <Box sx={{ lineHeight: 1 }}>
      <Typography sx={{ color: 'text.secondary', fontSize: 'body3.fontSize' }}>{`${Math.round(progress)}%`}</Typography>
    </Box>
  </Box>
);

export default LinearProgressWithLabel;
