import { Box, Stack, SvgIcon, SxProps, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns/format';
import DropZoneArea from '@/components/DropZoneArea';
import { acceptedFileTypes } from '@/utils/consts';
import { DocumentMetadata } from '@/api/generated';
import Icon from '@/components/Icon/Icon';
import UploadIcon from '@/assets/upload-icon.svg?react';
import OneDriveIcon from '@/assets/onedrive.svg?react';
import GoogleDriveIcon from '@/assets/google-drive.svg?react';
import AutodeskIcon from '@/assets/autodesk.svg?react';
import { DocumentVersionNode, ImportDriveFilesFn } from '@/views/Projects/components/ProjectFormDialog/types';
import { STATUS } from '@/utils/enums';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import DriveButton from '@/views/Projects/components/ProjectFormDialog/components/DriveButton';
import { useDrivesImport } from '@/views/Projects/components/ProjectFormDialog/hooks/useDrivesImport';
import { isSocialLoginError } from '@/views/Projects/components/ProjectFormDialog/utils/isSocialLoginError';
import { Descope } from '@descope/react-sdk';
import DialogCloseButton from '@/components/Dialog/DialogCloseButton';

interface DocumentUploadProps {
  sx?: SxProps;
  document: DocumentMetadata;
  versionsNodes: DocumentVersionNode[];
  uploadVersion: (files: File[]) => void;
  importDriveFiles: ImportDriveFilesFn;
  onVersionClick: (documentId: string) => void;
  onClose: () => void;
}

const VersionsUpload: FC<DocumentUploadProps> = ({
  sx,
  document,
  versionsNodes,
  uploadVersion,
  importDriveFiles,
  onVersionClick,
  onClose,
}) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadVersion,
    accept: acceptedFileTypes,
    multiple: false,
  });

  const { redirectUrl, errorContext, onOneDriveClick, onGoogleDriveClick, onAutodeskDriveClick } = useDrivesImport({
    importDriveFiles,
    documentId: document._id,
  });

  return (
    <Stack
      sx={{
        ...sx,
        position: 'relative',
        minWidth: 370,
        minHeight: 0,
        height: '100%',
        p: 3,
        overflow: 'auto',
        borderRadius: 4,
        boxShadow: 6,
      }}
    >
      {errorContext && errorContext.type === 'google' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-google" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'onedrive' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-microsoft" redirectUrl={redirectUrl} />
      )}
      {errorContext && errorContext.type === 'autodesk' && redirectUrl && isSocialLoginError(errorContext.error) && (
        <Descope flowId="re-auth-autodesk" redirectUrl={redirectUrl} />
      )}

      <DialogCloseButton onClick={() => onClose()} />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Icon name="version" fontSize="large" />
        {t('uploadFiles.versionsTitle')}
      </Box>

      <Table size="small" sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 'body3.fontSize' }}>{t('uploadFiles.tableFileName')}</TableCell>
            <TableCell sx={{ width: 150, fontSize: 'body3.fontSize' }}>{t('uploadFiles.tableDate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key="main"
            onClick={() => onVersionClick(document._id!)}
            sx={{ cursor: 'pointer', '&:hover': { backgroundColor: palette.grey[300] } }}
          >
            <TableCell sx={{ borderBottom: 0 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pt: 1, pb: 2, fontSize: 'body3.fontSize' }}>
                <Icon name="pdf" fontSize="small" />
                {document.filename}
              </Box>
            </TableCell>
            <TableCell sx={{ pt: 1, pb: 2, borderBottom: 0, fontSize: 'body3.fontSize' }}>
              {format(new Date(document.last_modified ?? ''), 'P')}
            </TableCell>
          </TableRow>

          {versionsNodes.map(versionNode => (
            <TableRow
              key={versionNode.id}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                '&:hover': { backgroundColor: versionNode.version ? palette.grey[300] : 'inherit' },
              }}
              onClick={() => versionNode.version && onVersionClick(versionNode.version.id)}
            >
              <TableCell
                sx={{
                  borderBottom: 0,
                  pt: 1,
                  pb: 2,
                  fontSize: 'body3.fontSize',
                  color: versionNode.status === STATUS.ERROR ? palette.grey[400] : palette.text.primary,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Icon name="pdfGray" fontSize="small" />
                  {document.filename}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  pt: 1,
                  pb: 2,
                  borderBottom: 0,
                  fontSize: 'body3.fontSize',
                  color: versionNode.status === STATUS.ERROR ? palette.grey[400] : palette.text.primary,
                }}
              >
                {format(new Date(versionNode.lastModified ?? ''), 'P')}

                {versionNode.status === STATUS.LOADING && (
                  <LinearProgressWithLabel
                    sx={{ position: 'absolute', left: 16, bottom: 0, right: 16 }}
                    progress={versionNode.progress}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <DropZoneArea sx={{ mt: 2 }} isDragActive={isDragActive} {...getRootProps()}>
        <input {...getInputProps()} />
        <Stack alignItems="center" p={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SvgIcon inheritViewBox component={UploadIcon} sx={{ fontSize: 28 }} />
            <Box sx={{ fontSize: 'body3.fontSize' }}>
              <Trans
                components={{
                  span: (
                    <span style={{ color: palette.primary.dark, fontWeight: 'fontWeightMedium', textDecoration: 'underline' }} />
                  ),
                }}
              >
                {t('uploadFiles.versionsDropzone')}
              </Trans>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', height: 120 }}>
            <DriveButton
              sx={{ width: 100 }}
              disabled
              icon={<GoogleDriveIcon style={{ width: 30 }} />}
              text={t('uploadFiles.clouds.google')}
              onClick={onGoogleDriveClick}
            />
            <DriveButton
              sx={{ width: 100 }}
              icon={<OneDriveIcon style={{ width: 40 }} />}
              text={t('uploadFiles.clouds.onedrive')}
              onClick={onOneDriveClick}
            />
            <DriveButton
              sx={{ width: 100 }}
              icon={<AutodeskIcon style={{ width: 40 }} />}
              text={t('uploadFiles.clouds.autodesk')}
              onClick={onAutodeskDriveClick}
            />
          </Box>
        </Stack>
      </DropZoneArea>
    </Stack>
  );
};

export default VersionsUpload;
