import { TableNode, TableRowNode, TableCellNode } from '@lexical/table';
import { FC } from 'react';
import { View } from '@react-pdf/renderer';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';

interface PdfTableProps {
  node: TableNode;
}

const PdfTableView: FC<PdfTableProps> = ({ node }) => (
  <View style={{ marginTop: 30, marginBottom: 30 }}>
    {node.getChildren<TableRowNode>().map(row => (
      <View wrap={false} key={row.getKey()} style={{ display: 'flex', flexDirection: 'row', minHeight: row.getHeight() ?? 28 }}>
        {row.getChildren<TableCellNode>().map(cell => (
          <View
            key={cell.getKey()}
            style={{
              width: cell.getWidth() ?? 75,
              backgroundColor: cell.getBackgroundColor() || cell.hasHeader() ? '#f2f3f5' : '#fff',
              border: 1,
              borderColor: '#bbb',
              paddingVertical: 6,
              paddingHorizontal: 8,
              marginTop: -1,
              marginLeft: -1,
            }}
          >
            <LexicalNodePdfView nodes={cell.getChildren()} />
          </View>
        ))}
      </View>
    ))}
  </View>
);
export default PdfTableView;
