import { LexicalEditor } from 'lexical';
import { useEffect } from 'react';

const useDispatchEditorClick = (editor: LexicalEditor | null) => {
  useEffect(() => {
    const root = editor?.getRootElement();
    if (!root) return;

    const handleRootClick = () => {
      document.dispatchEvent(new CustomEvent('lexicalEditorClick'));
    };
    root.addEventListener('click', handleRootClick);

    return () => {
      root.removeEventListener('click', handleRootClick);
    };
  }, [editor]);
};

export default useDispatchEditorClick;
