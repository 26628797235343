import PSPDFKit from 'pspdfkit';
import { MutableRefObject, useCallback } from 'react';
import { usePspdfkit } from '@/containers/DocumentEditor/internalHooks/usePspdfkit';
import { CompareSourcesConfig } from '@/containers/DocumentEditor/types';
import withInstantJsonEnvelop from '@/containers/DocumentEditor/utils/withInstantJsonEnvelop';
import { isAnnotationsWithPoints } from '@/containers/DocumentEditor/guards/isAnnotationsWithPoints';
import { useGetProjectsIdDocumentsDocumentId } from '@/api/generated';
import { useProject } from '@/views/Project/hooks/useProject';

type Params = {
  isEnabled?: boolean;
  isSplitMode: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  secondContainerRef: MutableRefObject<HTMLDivElement | null>;
  compareSources?: CompareSourcesConfig | null;
};

const URL_STORE_TIME = 1000 * 60 * 20; // 20 minutes

export const useComparePspdf = ({ isEnabled, isSplitMode, containerRef, secondContainerRef, compareSources }: Params) => {
  const { projectSlug } = useProject();
  const { firstSource, secondSource, autoCompare = true } = compareSources ?? {};
  const { documentId: initialDocumentId, page: firstPage, annotations: firstAnnotations = [] } = firstSource ?? {};
  const { documentId: updatedDocumentId, page: secondPage, annotations: secondAnnotations = [] } = secondSource ?? {};

  const { data: firstDocument, isLoading: firstDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: initialDocumentId ? initialDocumentId! : 'fake' },
    { query: { enabled: isEnabled && !!initialDocumentId, staleTime: URL_STORE_TIME } },
  );

  const { data: secondDocument, isLoading: secondDocumentIsLoading } = useGetProjectsIdDocumentsDocumentId(
    projectSlug,
    { document_id: updatedDocumentId ? updatedDocumentId! : 'fake' },
    { query: { enabled: isEnabled && !!updatedDocumentId, staleTime: URL_STORE_TIME } },
  );

  const isCompareEnabled = !!isEnabled && !!compareSources;
  const firstKey = firstDocument && compareSources ? `${firstDocument._id}_${firstSource?.page}` : undefined;
  const secondKey = secondDocument && compareSources ? `${secondDocument._id}_${secondSource?.page}` : undefined;
  const key = firstKey && secondKey ? `${firstKey}_${secondKey}_${autoCompare}` : undefined;
  const firstUrl = firstDocument
    ? firstDocument?.urls && firstPage !== undefined
      ? firstDocument.urls[firstPage]
      : firstDocument.url
    : undefined;
  const secondUrl = secondDocument
    ? secondDocument?.urls && secondPage !== undefined
      ? secondDocument.urls[secondPage]
      : secondDocument.url
    : undefined;

  const {
    instance,
    isLoading: isFirstLoading,
    unload: unloadFirst,
  } = usePspdfkit({
    isEnabled: isCompareEnabled && !!firstUrl,
    key: isSplitMode ? firstKey : key,
    containerRef,
    url: firstUrl,
    instanceConfig: {
      initialViewState: PSPDFKit.viewStateFromOpenParameters(new PSPDFKit.ViewState({ showToolbar: false, readOnly: true })),
      instantJSON: withInstantJsonEnvelop(
        firstAnnotations.filter(annotation => !isAnnotationsWithPoints(annotation) || annotation.points.length > 1),
      ),
    },
    onLoad: async (nextInstance, controller) => {
      if (!firstUrl || !secondUrl || isSplitMode || !secondSource) {
        return;
      }

      try {
        const source = await fetch(secondUrl, { signal: controller.signal }).then(response => response.arrayBuffer());
        await nextInstance.setDocumentComparisonMode({
          documentA: { source: 'USE_OPEN_DOCUMENT', pageIndex: 0 },
          documentB: { source, pageIndex: 0 },
          autoCompare: !!autoCompare,
          strokeColors: {
            documentA: PSPDFKit.Color.BLUE,
            documentB: PSPDFKit.Color.RED,
          },
        });
      } catch (error) {
        console.error('Error while loading source', error);
        /* empty */
      }
    },
  });

  const { isLoading: isSecondLoading, unload: unloadSecond } = usePspdfkit({
    isEnabled: isCompareEnabled && !!secondUrl && isSplitMode,
    key: secondKey,
    containerRef: secondContainerRef,
    url: secondUrl,
    instanceConfig: {
      initialViewState: PSPDFKit.viewStateFromOpenParameters(
        new PSPDFKit.ViewState({ showToolbar: false, readOnly: true, enableAlwaysScrollToZoom: false }),
      ),
      instantJSON: withInstantJsonEnvelop(
        secondAnnotations.filter(annotation => !isAnnotationsWithPoints(annotation) || annotation.points.length > 1),
      ),
    },
  });

  const isLoading = isSplitMode ? isFirstLoading && isSecondLoading : isFirstLoading;
  const unload = useCallback(() => {
    unloadFirst();
    unloadSecond();
  }, [unloadFirst, unloadSecond]);

  return {
    instance,
    comparedDocuments: firstDocument && secondDocument ? { firstDocument, secondDocument } : undefined,
    isLoading: firstDocumentIsLoading || secondDocumentIsLoading || isLoading,
    unload,
  };
};
