import Header from '@/components/Header/Header';
import { Outlet } from 'react-router-dom';
import { FC, memo, Suspense } from 'react';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import useHeaderHeight from '@/hooks/useHeaderHeight';
import Loader from '@/components/Loader/Loader';
import OpenKnowledge from '@/views/Knowledge/OpenKnowledge';
import { KnowledgeProvider } from '@/views/Knowledge/useKnowledge';
import { ProjectFormDialogProvider } from '@/providers/ProjectFormDialogProvider';
import EmptyDocumentProjectProvider from '@/views/Project/providers/EmptyDocumentProjectProvider';
import { ROUTER_IDS } from '@/services/linker';
import useRouteId from '@/hooks/useRouteId';

const Root: FC = memo(function Root() {
  const { pageTitle } = usePageTitle();
  const headerHeight = useHeaderHeight();
  const routeId = useRouteId();

  return (
    <EmptyDocumentProjectProvider>
      <KnowledgeProvider>
        <ProjectFormDialogProvider>
          <Box sx={{ minHeight: '100vh' }}>
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            <Header />
            <Box component="main" sx={{ height: `calc(100vh - ${headerHeight}px)`, zIndex: 100, position: 'relative' }}>
              <Suspense fallback={<Loader id="root-Suspense" />}>
                <Outlet />
              </Suspense>
            </Box>
            {routeId !== ROUTER_IDS.ORG_KB && <OpenKnowledge />}
          </Box>
        </ProjectFormDialogProvider>
      </KnowledgeProvider>
    </EmptyDocumentProjectProvider>
  );
});

export default Root;
