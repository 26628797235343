type Options = {
  verticalGap?: number;
  horizontalOffset?: number;
  addElementHeight?: boolean;
};

export const setFloatingElemPositionUnderTarget = (
  targetRect: DOMRect | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  { verticalGap = 0, horizontalOffset = 0, addElementHeight = false }: Options = { verticalGap: 0, horizontalOffset: 0 },
): void => {
  const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.transform = 'translate(-10000px, -10000px)';
    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  const heightToAdd = addElementHeight ? targetRect.height : 0;
  let top = targetRect.top + heightToAdd + verticalGap;
  let left = editorScrollerRect.left + horizontalOffset;

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
};
