import { PLACEHOLDER_ELEMENT_ID } from '@/containers/PagesEditor/constants';

const getDOMRangeRect = (nativeSelection: Selection, rootElement: HTMLElement): DOMRect => {
  const domRange = nativeSelection.getRangeAt(0);

  let rect;

  if (nativeSelection.anchorNode === rootElement) {
    const placeholderElem = document.getElementById(PLACEHOLDER_ELEMENT_ID);
    rect = placeholderElem ? placeholderElem.getBoundingClientRect() : rootElement.getBoundingClientRect();
  } else if (domRange.collapsed && domRange.endContainer.firstChild?.parentElement) {
    rect = domRange.endContainer.firstChild.parentElement.getBoundingClientRect();
    rect.y = rect.y - 10;
  } else {
    rect = domRange.getBoundingClientRect();
  }

  return rect;
};

export default getDOMRangeRect;
