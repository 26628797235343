import { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { QuoteNode } from '@lexical/rich-text';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';

interface QuoteViewProps {
  node: QuoteNode;
}

const QuoteView: FC<QuoteViewProps> = ({ node }) => (
  <View style={{ flexDirection: 'row', gap: 10, color: '#65676b', marginLeft: 20, marginBottom: 10 }}>
    <View style={{ width: 2, backgroundColor: '#65676b' }} />
    <LexicalNodePdfView nodes={node.getChildren()} />
  </View>
);

export default QuoteView;
