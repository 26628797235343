import { EditedPage } from '@/views/Project/views/AutomatePages/types';
import { PageResponse } from '@/api/generated';

export const isExistedPage = (page: EditedPage): page is PageResponse => !!page._id;

const getPageIdsLocalStorage = () => JSON.parse(localStorage.getItem('automatePageIds') ?? '{}') as Record<string, string>;

export const getPageIdFromLocalStorage = (projectSlug: string) => getPageIdsLocalStorage()[projectSlug];

export const savePageIdToLocalStorage = (projectSlug: string, pageId: string) => {
  const pageIdsStorage = getPageIdsLocalStorage();
  pageIdsStorage[projectSlug] = pageId;
  localStorage.setItem('automatePageIds', JSON.stringify(pageIdsStorage));
};
