import { FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Box, Collapse, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { useFormContext } from 'react-hook-form';
import TextInput from '@/components/TextInput/TextInput';
import SelectControl from '@/components/SelectControl';
import Stakeholders from '@/views/Projects/components/ProjectFormDialog/components/Stakeholders';
import { ProjectFormValue } from '@/views/Projects/components/ProjectFormDialog/types';
import Icon from '@/components/Icon/Icon';
import * as React from 'react';
import Address from '@/views/Projects/components/ProjectFormDialog/components/Address';

interface ProjectFormProps {
  onSaveField: (data: ProjectFormValue) => Promise<void>;
}

const fieldNames: Array<keyof ProjectFormValue> = ['name', 'address', 'owner', 'type', 'delivery_method', 'project_size'];
const fieldIds = fieldNames.map(name => `projectUpdate-${name}`);

const ProjectForm: FC<ProjectFormProps> = ({ onSaveField }) => {
  const { t } = useTranslation('projectUpdate');
  const { palette } = useTheme();
  const { control, setValue, setFocus, getValues, watch } = useFormContext<ProjectFormValue>();

  const [isOpened, setIsOpened] = useState(false);
  const [coordinates, setCoordinates] = useState<{ lat?: number; lng?: number }>({ lat: 0, lng: 0 });
  const [isEditProjectDetails, setIsEditProjectDetails] = useState(false);
  const [isEditProjectTitle, setIsEditProjectTitle] = useState(false);
  const slug = getValues('slug');
  const name = watch('name');
  const prevValues = getValues();

  const commonFieldStyle = {
    '& .MuiInputBase-root': { backgroundColor: palette.controls.background },
    input: {
      fontSize: 'body3.fontSize',
      p: 1.5,
    },
  };

  useEffect(() => {
    isEditProjectTitle && setFocus('name');
  }, [isEditProjectTitle]);

  useEffect(() => {
    !name && setIsEditProjectTitle(true);
  }, [name]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code !== Key.Enter) return;
    event.preventDefault();

    const currentFieldIndex = fieldIds.findIndex(id => (event.target as HTMLInputElement).id === id);

    if (currentFieldIndex >= 0 && fieldIds[currentFieldIndex + 1]) {
      setFocus(fieldNames[currentFieldIndex + 1]);
    } else {
      setIsEditProjectDetails(false);
      setIsEditProjectTitle(false);
    }
  };

  useEffect(() => {
    const location = getValues('location');
    if (!location?.lat || !location.lng) return;
    setCoordinates({ lat: location.lat, lng: location.lng });
  }, []);

  const onPlaceSelected = useCallback((place: google.maps.places.PlaceResult) => {
    const getComponentByType = (targetType: string) =>
      place.address_components?.find(({ types }) => types.find(type => type === targetType))?.long_name;

    setValue('location', {
      number: parseInt(getComponentByType('street_number') || '', 10) || null,
      street: getComponentByType('route'),
      state: getComponentByType('administrative_area_level_1'),
      city: getComponentByType('locality'),
      country: getComponentByType('country'),
      zip: getComponentByType('postal_code'),
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    });
    setCoordinates({ lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng() });
  }, []);

  const handleBlur = () => {
    const updatedValues = getValues();
    if (
      updatedValues.owner === prevValues.owner &&
      updatedValues.delivery_method === prevValues.delivery_method &&
      updatedValues.project_size === prevValues.project_size &&
      updatedValues.type === prevValues.type
    )
      return;
    onSaveField(updatedValues);
  };

  return (
    <Stack gap={1.25}>
      <Stack flexDirection="row" alignItems="center">
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          sx={{
            width: isEditProjectTitle ? 0 : 'auto',
            overflow: 'hidden',
            '&:hover .ProjectForm__title-edit': { opacity: isEditProjectTitle ? 0 : 1 },
          }}
        >
          <Typography sx={{ fontSize: 'h2.fontSize', fontWeight: 'bold', color: palette.controls.label }}>{name}</Typography>

          <IconButton
            disabled={isEditProjectDetails}
            onClick={() => setIsEditProjectTitle(true)}
            className="ProjectForm__title-edit"
            sx={{ opacity: 0 }}
          >
            <Icon fontSize="small" name="edit" />
          </IconButton>
        </Stack>

        {isEditProjectTitle && (
          <TextInput
            autoFocus
            required
            sx={commonFieldStyle}
            label={t('details.name')}
            placeholder={t('details.namePlaceholder')}
            control={control}
            id={fieldIds[0]}
            name={fieldNames[0]}
            labelSx={{ fontWeight: 'fontWeightMedium' }}
            translate="projectUpdate"
            onKeyDown={handleKeyDown}
            onBlur={() => {
              const allValues = getValues();
              const currentName = allValues.name;
              if (currentName.trim()) {
                onSaveField(allValues);
                setIsEditProjectTitle(false);
              }
            }}
          />
        )}
      </Stack>

      <Box sx={{ mt: 2 }}>
        <Address
          label={t('details.address')}
          placeholder={t('details.addressPlaceholder')}
          id={fieldIds[1]}
          name={fieldNames[1]}
          control={control}
          labelSx={{ fontWeight: 'fontWeightMedium' }}
          options={{ types: ['address'] }}
          onPlaceSelected={onPlaceSelected}
          onKeyDown={handleKeyDown}
          coordinates={coordinates}
          slug={slug}
          getValues={getValues}
          onSaveField={onSaveField}
        />
      </Box>

      <Collapse in={isOpened} sx={{ '& ~ div': { fontWeight: 'fontWeightMedium' } }}>
        <Stack gap={3} sx={{ mt: 2 }}>
          <Box sx={{ fontSize: 'h3.fontSize', fontWeight: 'bold', color: palette.controls.text }}>{t('details.title')}</Box>
          <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: '1fr 1fr' }}>
            <TextInput
              label={t('details.generalContractor')}
              placeholder={t('details.generalContractorPlaceholder')}
              control={control}
              id={fieldIds[2]}
              name={fieldNames[2]}
              labelSx={{ fontWeight: 'fontWeightMedium' }}
              translate="projectUpdate"
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              sx={commonFieldStyle}
            />

            <SelectControl
              label={t('details.type')}
              labelSx={{ fontWeight: 'fontWeightMedium', color: palette.controls.text }}
              options={t('projectTypes', { returnObjects: true })}
              placeholder={t('details.typePlaceholder')}
              control={control}
              id={fieldIds[3]}
              name={fieldNames[3]}
              freeSolo
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              sx={{
                ...commonFieldStyle,
                input: {
                  fontSize: 'body3.fontSize',
                  '&.MuiAutocomplete-input.MuiOutlinedInput-input': {
                    p: 1.5,
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: '1fr 1fr' }}>
            <SelectControl
              label={t('details.deliveryMethod')}
              labelSx={{ fontWeight: 'fontWeightMedium', color: palette.controls.text }}
              options={t('deliveryMethods', { returnObjects: true })}
              placeholder={t('details.deliveryMethodPlaceholder')}
              control={control}
              id={fieldIds[4]}
              name={fieldNames[4]}
              freeSolo
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              sx={{
                ...commonFieldStyle,
                input: {
                  fontSize: 'body3.fontSize',
                  '&.MuiAutocomplete-input.MuiOutlinedInput-input': {
                    p: 1.5,
                  },
                },
              }}
            />
            <TextInput
              label={t('details.projectSize')}
              placeholder={t('details.projectSizePlaceholder')}
              control={control}
              id={fieldIds[5]}
              name={fieldNames[5]}
              labelSx={{ fontWeight: 'fontWeightMedium' }}
              translate="projectUpdate"
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              sx={commonFieldStyle}
            />
          </Box>
          <Stakeholders control={control} onSaveField={onSaveField} getValues={getValues} setFocus={setFocus} />
        </Stack>
      </Collapse>

      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
          alignItems: 'center',
          alignSelf: 'flex-start',
          fontSize: 'body3.fontSize',
          color: palette.primary.dark,
          cursor: 'pointer',
          '&:hover': { textDecoration: 'underline' },
        }}
        onClick={() => setIsOpened(prev => !prev)}
      >
        {isOpened ? t('details.showLess') : t('details.showMore')}
        <Icon name="arrowDown" fontSize="small" sx={{ transform: `rotate(${isOpened ? 180 : 0}deg)` }} />
      </Box>
    </Stack>
  );
};

export default ProjectForm;
