import { FC } from 'react';
import { View } from '@react-pdf/renderer';
import LexicalNodePdfView from '@/components/LexicalPdfExport/components/LexicalNodePdfView';
import { LayoutContainerNode } from '@lexical/playground/nodes/LayoutContainerNode';
import { LayoutItemNode } from '@lexical/playground/nodes/LayoutItemNode';

interface CodeViewProps {
  node: LayoutContainerNode;
}

const LayoutView: FC<CodeViewProps> = ({ node }) => {
  const gridColumns = node
    .getTemplateColumns()
    .split(' ')
    .filter(Boolean)
    .map(fractions => parseInt(fractions));
  const sectionsCount = gridColumns.reduce((acc, fractionsCount) => acc + fractionsCount, 0);

  const calcPercentage = (fractionsCount: number) => fractionsCount * (100 / sectionsCount);

  return (
    <View style={{ flexDirection: 'row', gap: 20, paddingVertical: 10 }}>
      {node.getChildren<LayoutItemNode>().map((child, index) => (
        <View style={{ flexShrink: 0, flexGrow: 0, width: `${calcPercentage(gridColumns[index])}%` }}>
          <LexicalNodePdfView nodes={child.getChildren()} />
        </View>
      ))}
    </View>
  );
};

export default LayoutView;
