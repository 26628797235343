import { Status } from '@/api/generated';
import { SORT_DIRECTION } from '@/enums/sort';
import { PathParam, generatePath } from 'react-router-dom';

type ParamObject = {
  [key in PathParam<string>]: string | null;
};
type SearchObject = string | string[][] | Record<string, string | undefined> | undefined; // type from URLSearchParams

export enum ROUTER_PATHS {
  HOME = '/',
  ORG_KB = '/org-kb',
  PROJECTS = '/projects',
  PROJECT = '/projects/:projectSlug',
  PROJECT_HOME_PAGE = '/projects/:projectSlug/homepage',
  PROJECT_TAKEOFF = '/projects/:projectSlug/takeoff',
  PROJECT_QUERIES = '/projects/:projectSlug/queries',
  PROJECT_PAGES = '/projects/:projectSlug/pages',
  PROJECT_COMPARE = '/projects/:projectSlug/compare',
  ORG_PAGES = '/org-pages',
}

export enum ROUTER_IDS {
  HOME = 'home',
  ORG_KB = 'org_kb',
  PROJECTS = 'projects',
  PROJECT = 'project',
  PROJECT_HOME_PAGE = 'project_homepage',
  PROJECT_TAKEOFF = 'project_takeoff',
  PROJECT_QUERIES = 'project_queries',
  PROJECT_PAGES = 'project_pages',
  PROJECT_COMPARE = 'project_compare',
  ORG_PAGES = 'org_pages',
}

export const PROJECT_ROUTER_IDS = [
  ROUTER_IDS.PROJECT_HOME_PAGE,
  ROUTER_IDS.PROJECT_TAKEOFF,
  ROUTER_IDS.PROJECT_QUERIES,
  ROUTER_IDS.PROJECT_PAGES,
  ROUTER_IDS.PROJECT_COMPARE,
];

const normalizeSearchParams = (searchParams: SearchObject = {}) =>
  Object.fromEntries(Object.entries(searchParams).filter(([, value]) => typeof value !== 'undefined'));

const asPath = (basePath: string, params?: ParamObject, searchParams?: SearchObject): string => {
  const path = generatePath(basePath, params);

  const searchParamsString = new URLSearchParams(normalizeSearchParams(searchParams)).toString();
  return [path, searchParamsString].filter(Boolean).join('?');
};

export const toHome = () => asPath(ROUTER_PATHS.HOME);

export const toOrgKb = () => asPath(ROUTER_PATHS.ORG_KB);

export const toOrgPages = () => asPath(ROUTER_PATHS.ORG_PAGES);

type FilterParams = { status?: Status; sortDir?: SORT_DIRECTION; sortBy?: string; searchFilter?: string };
export const toProjects = ({ status = Status.active, sortDir, sortBy, searchFilter }: FilterParams = {}) =>
  asPath(ROUTER_PATHS.PROJECTS, undefined, { status, sortDir, sortBy, searchFilter });

export const toProjectHomepage = ({
  projectSlug,
  documentId,
  page,
}: {
  projectSlug: string;
  documentId?: string;
  page?: string;
}) => asPath(ROUTER_PATHS.PROJECT_HOME_PAGE, { projectSlug }, { documentId, page });

export const toProjectTakeoff = ({
  projectSlug,
  documentId,
  annotationGroupId,
  page,
}: {
  projectSlug: string;
  documentId?: string;
  annotationGroupId?: string;
  page?: string;
}) => asPath(ROUTER_PATHS.PROJECT_TAKEOFF, { projectSlug }, { documentId, annotationGroupId, page });

export const toProjectQueries = ({
  projectSlug,
  documentId,
  queryId,
  matchId,
  sortBy = 'priority:asc,last_modified:desc',
  filterBy,
  page,
  action,
}: {
  projectSlug: string;
  documentId?: string;
  queryId?: string;
  matchId?: string;
  sortBy?: string;
  filterBy?: string;
  page?: string;
  action?: string;
}) => asPath(ROUTER_PATHS.PROJECT_QUERIES, { projectSlug }, { documentId, sortBy, filterBy, queryId, matchId, page, action });

export const toProjectPages = ({
  projectSlug,
  documentId,
  pageId,
  page,
}: {
  projectSlug: string;
  documentId?: string;
  pageId?: string;
  page?: string;
}) => asPath(ROUTER_PATHS.PROJECT_PAGES, { projectSlug }, { documentId, pageId, page });

export const toProjectCompare = ({
  projectSlug,
  documentId,
  page,
  compareId,
}: {
  projectSlug: string;
  documentId?: string;
  page?: string;
  compareId?: string;
}) => asPath(ROUTER_PATHS.PROJECT_COMPARE, { projectSlug }, { documentId, page, compareId });

export const ROUTER_PATH_LINKER = {
  '': () => '',
  [ROUTER_IDS.HOME]: toHome,
  [ROUTER_IDS.ORG_KB]: toOrgKb,
  [ROUTER_IDS.PROJECTS]: toProjects,
  [ROUTER_IDS.PROJECT]: toProjectHomepage,
  [ROUTER_IDS.PROJECT_HOME_PAGE]: toProjectHomepage,
  [ROUTER_IDS.PROJECT_TAKEOFF]: toProjectTakeoff,
  [ROUTER_IDS.PROJECT_QUERIES]: toProjectQueries,
  [ROUTER_IDS.PROJECT_PAGES]: toProjectPages,
  [ROUTER_IDS.PROJECT_COMPARE]: toProjectCompare,
  [ROUTER_IDS.ORG_PAGES]: toOrgPages,
};
