import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import Icon, { IconNames } from '@/components/Icon/Icon';
import { FC, ReactNode } from 'react';

interface SubjectWrapperProps {
  children: ReactNode;
  sx?: SxProps;
  iconName: IconNames;
  label: string;
}

const SubjectWrapper: FC<SubjectWrapperProps> = ({ children, sx, iconName, label }) => {
  const { palette } = useTheme();

  return (
    <Stack>
      <Stack flexDirection="row" alignItems="center" gap={0.5} sx={{ color: palette.secondary.light, mb: 0.5 }}>
        <Icon name={iconName} fontSize="small" />
        <Typography sx={{ fontSize: 'body3.fontSize', lineHeight: 1 }}>{label}</Typography>
      </Stack>
      <Box sx={{ border: `1px solid ${palette.grey[200]}`, borderRadius: 2, pt: 3, ...sx }}>{children}</Box>
    </Stack>
  );
};

export default SubjectWrapper;
